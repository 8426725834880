import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  Tabs,
  Tab,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { logo2 } from '../assets';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { FaDribbble, FaFacebookF, FaTwitter } from 'react-icons/fa';
import ModalForm from '../components/ModalForm/ModalForm';
import { toast } from 'react-toastify';
import ThreeFormsModal from '../components/ModalForm/ThreeFormsModal';

interface HeaderProps {
  selectedTab: string;
  isScrolled: boolean;
}

const Header: React.FC<HeaderProps> = ({ selectedTab, isScrolled }) => {
  const { id } = useParams(); // get project id from route params
  const navigate = useNavigate();
  const theme = useTheme();

  // Drawer state
  const [drawerOpen, setDrawerOpen] = useState(false);
  // Modal states
  const [openBrochureModal, setOpenBrochureModal] = useState(false);
  const [openInquiryModal, setOpenInquiryModal] = useState(false);

  // Handle tab change
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    navigate(newValue); // navigate to new route
    setDrawerOpen(false); // close drawer after selecting
  };

  // Open Brochure Modal
  const handleOpenBrochure = () => {
    if (id) {
      setOpenBrochureModal(true);
      // toast.success('Brochure is being downloaded');
    } else {
      toast.error('Please select a project to download the brochure');
    }
  };

  // Open Inquiry Modal
  const handleOpenInquiry = () => {
    setOpenInquiryModal(true);
  };

  // Close Modals
  const handleCloseModals = () => {
    setOpenBrochureModal(false);
    setOpenInquiryModal(false);
  };

  // Toggle Drawer
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Tabs Labels
  const tabLabels = [
    { label: 'Home', route: '/home' },
    { label: 'Projects', route: '/projects' },
    { label: 'About', route: '/about' },
    { label: 'Blog', route: '/blog' },
    { label: 'Contact Us', route: '/contact' },
    { label: 'NRI', route: '/nri' },
  ];

  // Header styling based on scroll state
  const headerStyle = {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    boxShadow: isScrolled ? '0 10px 50px 0 rgba(46, 56, 220, .2)' : 'none',
    transition: 'box-shadow 0.3s ease, height 0.3s ease',
    height: isScrolled ? '63px' : '80px',
  };

  return (
    <>
      <AppBar position="fixed" sx={headerStyle}>
        <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
          {/* Logo */}
          <Box
            sx={{ display: 'flex', alignItems: 'center', ml: { xs: 5, md: 8 }, cursor: 'pointer' }}
            onClick={() => navigate('/home')}
          >
            <img
              src={logo2}
              alt="Logo"
              style={{
                height: isScrolled ? '40px' : '45px',
                transform: 'scale(1.5)',
                transition: 'all 0.25s ease',
              }}
            />
          </Box>

          {/* Tabs for desktop */}
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              flexGrow: 1,
              justifyContent: 'flex-end',
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              textColor="inherit"
              indicatorColor="secondary"
              sx={{
                '& .MuiTab-root': {
                  minWidth: 'auto',
                  marginLeft: 2,
                  color: '#000000',
                  fontSize: '16px',
                  fontWeight: 'bold',
                },
                '& .Mui-selected': {
                  color: theme.palette.primary.main,
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              {tabLabels.map((tab, index) => (
                <Tab key={index} label={tab.label} value={tab.route} />
              ))}
            </Tabs>

            {/* Button: Brochure or Inquiry */}
            {id ? (
              <Button variant="contained" sx={{ ml: 3, color: '#FFFFFF' }} onClick={handleOpenBrochure}>
                Download Brochure
              </Button>
            ) : (
              <Button variant="contained" sx={{ ml: 3, color: '#FFFFFF' }} onClick={handleOpenInquiry}>
                Enquire Now
              </Button>
            )}
          </Box>

          {/* Hamburger Menu for mobile */}
          <IconButton
            edge="start"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{
              display: { xs: 'block', md: 'none' },
              color: '#000000',
              '&:hover': {
                color: '#da2e31',
                transition: 'all 0.3s ease',
              },
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile navigation */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: 250,
            backgroundColor: theme.palette.background.default,
            border: 'none', 
          },
        }}
      >
        <Box sx={{ width: 250 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1, backgroundColor: '#da2e31' }}>
            <IconButton
              onClick={handleDrawerToggle}
              sx={{
                color: '#FFFFFF',
                '&:hover': {
                  color: '#0b78b7',
                  transition: 'all 0.3s ease',
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <List>
            {tabLabels.map((tab, index) => (
              <ListItem
                button
                key={index}
                onClick={() => handleTabChange(null as any, tab.route)}
                sx={{
                  backgroundColor: selectedTab === tab.route ? '#da2e31' : '',
                  color : selectedTab === tab.route ? '#FFFFFF' : '#000000',
                  '&:hover': {
                    backgroundColor: '#da2e31',
                    transition: 'all 0.3s ease',
                    color: '#FFFFFF',
                    '& .MuiListItemText-primary': {
                      color: '#FFFFFF',
                    },
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: '550',
                        color: selectedTab === tab.route ? '#FFFFFF' : '#000000',
                      }}
                    >
                      {tab.label}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
          <Divider sx={{ borderColor: '#da2e31' }} />
          <List>
            <ListItem
              button
              onClick={id ? handleOpenBrochure : handleOpenInquiry}  // Adjust the action based on whether the project id exists
              sx={{
                '&:hover': {
                  backgroundColor: '#da2e31',
                  transition: 'all 0.3s ease',
                  color: '#FFFFFF',
                },
              }}
            >
              <ListItemText
                primary={
                  <Typography variant="body1" sx={{ color: '#000000', fontWeight: '550' }}>
                    {id ? 'Download Brochure' : 'Enquire Now'}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      {/* Modals */}
      {id && <ModalForm open={openBrochureModal} handleClose={handleCloseModals} projectId={id} />}
      <ThreeFormsModal open={openInquiryModal} onClose={handleCloseModals} />
    </>
  );
};

export default Header;
