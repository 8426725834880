import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, Grid, Button } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import CustomInput from '../../components/Inputs/CustomInput';
import { ContactUsImages } from '../../assets';

const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: 0,
    boxShadow: 'none',
    textAlign: 'center',
    padding: theme.spacing(2),
    border: '1px solid #e0e0e0',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
}));

const LogoImage = styled('img')(({ theme }) => ({
    width: '50px',
    height: 'auto',
    marginRight: theme.spacing(2),
}));

const ContactUsDetails: React.FC = () => {
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [saveDetails, setSaveDetails] = useState<boolean>(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://realty-yards-backend.onrender.com/emailform', {
                name,
                email,
                mobileNumber: phoneNumber,
                message,
            });

            if (response.status === 201) {
                alert('Message sent successfully!');
                setName('');
                setEmail('');
                setPhoneNumber('');
                setMessage('');
                setSaveDetails(false);
            }
        } catch (error) {
            console.error('Error sending message:', error);
            alert('Failed to send message. Please try again.');
        }
    };

    return (
        <Box sx={{ px: { xs: 2, sm: 2, md: 4, lg: 6 } }}>
            <Grid container spacing={3} sx={{ display: 'flex', alignItems: 'stretch' }}>
                {/* Contact Details Section */}
                <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
                    <StyledCard>
                        <CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <LogoImage src={ContactUsImages.emailimage} alt="Email Logo" />
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 700 }}>
                                        Email Address
                                    </Typography>
                                    <Typography variant="caption" color="primary">
                                        <a href="mailto:info@realtyyards.com" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            info@realtyyards.com
                                        </a>
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <LogoImage src={ContactUsImages.mobileimage} alt="Mobile Logo" />
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 700 }}>
                                        Mobile Number
                                    </Typography>
                                    <a href="tel:+919247347447" style={{ textDecoration: 'none' }}>
                                        <Typography variant="caption" color="primary">
                                            +91 9 247 347 447
                                        </Typography>
                                    </a>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <LogoImage src={ContactUsImages.addressimage} alt="Address Logo" />
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 700 }}>
                                        Address
                                    </Typography>
                                    <Typography variant="caption" color="primary">
                                        5th Floor, Survey No.135, Brundavan Colony, Plot No. 36, Gandipet Main Rd
                                        <br />
                                        Narsingi, Hyderabad, Telangana 500075
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </StyledCard>
                </Grid>

                {/* Contact Form Section */}
                <Grid item xs={12} md={8} sx={{ display: 'flex' }}>
                    <StyledCard>
                        <form onSubmit={handleSubmit} style={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomInput
                                        placeholder="Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomInput
                                        placeholder="Email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomInput
                                        placeholder="Phone Number"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomInput
                                        placeholder="Message"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        multiline
                                        rows={3}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Button type="submit" variant="contained" color="primary">
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </StyledCard>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ContactUsDetails;
