import React from 'react';
import { Box, Container, Grid, Typography, Link as MUILink } from '@mui/material';
import { FaHome, FaHeadset, FaMapMarkerAlt } from 'react-icons/fa';
import { useTheme } from '@mui/material/styles';

interface AboutUsSectionProps {
  imageSrc: string;
}

const AboutUsSectionOne: React.FC<AboutUsSectionProps> = ({ imageSrc }) => {
  const theme = useTheme();

  return (
    <Box sx={{ px :{ xs : 2 , sm : 4 , md : 6 , lg : 8 } , py : 4, backgroundColor: theme.palette.background.default }}>
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <img src={imageSrc} alt="About Us" style={{ width: '100%', borderRadius: '10px' }} />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box sx={{ p: 2, textAlign: 'left' }}>
              <Typography variant="body1" color="primary.main" sx={{ mb: 2 }}>
                About Us
              </Typography>
              <Typography variant="h3" sx={{ mb: 3, fontWeight: 700, color: 'black' }}>
                Building Tomorrow’s Landmarks Today
              </Typography>
              <Typography variant="caption" paragraph sx={{ textAlign: 'justify' , mb:2}}>
                With over 40 years of excellence in the real estate industry, We are committed to crafting homes that stand the test of time. Our expertise and dedication ensure that every project we undertake exceeds the expectations of our clients.              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                <FeatureItem
                  icon={<FaHome style={{ color: '#da2e31', fontSize: '40px' }} />}
                  title={
                    <MUILink href="#" sx={{ color: 'black', '&:hover': { color: 'primary.main' } }}>
                      Trusted Real Estate Advisors
                    </MUILink>
                  }
                  description="We pride ourselves on offering personalized real estate services that cater to the unique needs of home buyers & investors in Hyderabad & Telangana."
                />
                <FeatureItem
                  icon={<FaHeadset style={{ color: '#da2e31', fontSize: '40px' }} />}
                  title={
                    <MUILink href="#" sx={{ color: 'black', '&:hover': { color: 'primary.main' } }}>
                      The Future of Luxurious Living
                    </MUILink>
                  }
                  description="Our developments are designed to provide the ultimate living experience, blending modern design with the highest standards of quality and comfort."
                />
                <FeatureItem
                  icon={<FaMapMarkerAlt style={{ color: '#da2e31', fontSize: '40px' }} />}
                  title={
                    <MUILink href="#" sx={{ color: 'black', '&:hover': { color: 'primary.main' } }}>
                      Your Vision, Our Expertise
                    </MUILink>
                  }
                  description="From concept to completion, our team of experienced professionals works closely with you to bring your vision to life, creating spaces that truly feel like home."
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

interface FeatureItemProps {
  icon: React.ReactNode;
  title: React.ReactNode;
  description: string;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ icon, title, description }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mb: 3,
        p: 2,
        borderColor: '#f4faff',
        borderRadius: '8px',
        transition: 'all 0.3s ease',
        position: 'relative',
        border: `2px solid #00000010`,
        '&:hover': {
          boxShadow: '2px 6px 6px rgba(0, 0, 0, 0.3)',
          transform: 'translateY(-10px)',
          backgroundColor: '#ffffff08',
          '&::before': {
            height: '80%',
            opacity: 1,
            visibility: 'visible',
          },
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '-2px',
          top: '50%',
          width: '4px',
          height: '0%',
          backgroundColor: '#da2e31',
          transition: 'all 0.5s ease',
          opacity: 0,
          visibility: 'hidden',
          transform: 'translateY(-50%)',
          borderRadius: '4px',
        },
      }}
    >
      <Box sx={{ mr: 2 }}>{icon}</Box>
      <Box>
        <Typography
          variant="body1"
          sx={{ fontWeight: 600, mb: 1, color: '#da2e31', '&:hover': { color: '#da2e31' } }}
        >
          {title}
        </Typography>
        <Typography variant="caption" sx={{ color: 'black' }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default AboutUsSectionOne;
