// VisionMissionValues.tsx
import React from 'react';
import { Box, Typography, Grid, useTheme, Paper } from '@mui/material';
import IntegrityIcon from '@mui/icons-material/Gavel';
import CustomerSatisfactionIcon from '@mui/icons-material/EmojiPeople';
import InnovationIcon from '@mui/icons-material/Lightbulb';
import SustainabilityIcon from '@mui/icons-material/Recycling'; // Updated icon

const VisionMissionValues: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        mt:3,
        backgroundColor: 'transparent',
        px :{ xs : 2 , sm : 4 , md : 6 , lg : 8 } , py : 4 ,
      }}
    >
      <Grid container spacing={5} justifyContent="center">
      <Typography variant="h3" gutterBottom sx={{ fontWeight: 700  }}>
          Building the Future of Real Estate with Excellence
          </Typography>

        {/* Vision Section */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={4}
            sx={{
              p: 4,
              borderRadius: 3,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: 700, color: theme.palette.primary.main, textAlign: 'left' }}
            >
              Vision
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'justify', color: theme.palette.text.primary }}>
              To lead the Hyderabad real esate landscape recognized for our unwavering commitment to quality, innovation, and customer delight. We strive to craft vibrant, sustainable communities that stand the test of time, fostering a legacy of excellence and happiness for generations to come.
              {/* To lead  real estate company in Hyderabad, recognized for our quality, innovation, and customer satisfaction. We aim to create sustainable and thriving communities that endure the test of time. */}
            </Typography>
          </Paper>
        </Grid>

        {/* Mission Section */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={4}
            sx={{
              p: 4,
              borderRadius: 3,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: 700, color: theme.palette.primary.main, textAlign: 'left' }}
            >
              Mission
            </Typography>
            <Typography variant="body2" sx={{ textAlign: 'justify', color: theme.palette.text.primary }}>
              At Realty Yards, our mission is to surpass expectations through unwavering integrity and a customer-centric approcah. We are dedicated to delivering exceptional projects that contribute to a brighter future for our communities, fostering trust and making a positive impact.
            </Typography>
          </Paper>
        </Grid>

        {/* Values Section */}
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom sx={{ color: 'primary.main' , fontWeight: 700 , mt: 2, mb:3 }}>
            Our Values
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  textAlign: 'center',
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 3,
                }}
              >
                <IntegrityIcon
                  sx={{
                    fontSize: 50,
                    color: theme.palette.primary.main,
                    mb: 2,
                  }}
                />
                <Typography variant="h6" sx={{ fontWeight: 600, color: theme.palette.text.primary }}>
                  Integrity
                </Typography>
                <Typography variant="caption" sx={{ mt: 1, color: theme.palette.text.secondary, textAlign : 'justify' }}>
                  We conduct our business with the highest ethical standards, ensuring transparency.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  textAlign: 'center',
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 3,
                }}
              >
                <CustomerSatisfactionIcon
                  sx={{
                    fontSize: 50,
                    color: theme.palette.primary.main,
                    mb: 2,
                  }}
                />
                <Typography variant="h6" sx={{ fontWeight: 600, color: theme.palette.text.primary }}>
                  Client Pleasure
                </Typography>
                <Typography variant="caption" sx={{ mt: 1, color: theme.palette.text.secondary , textAlign : 'justify' }}>
                  We prioritize our clients' needs and strive to exceed their expectations at every step of the process.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  textAlign: 'center',
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 3,
                }}
              >
                <InnovationIcon
                  sx={{
                    fontSize: 50,
                    color: theme.palette.primary.main,
                    mb: 2,
                  }}
                />
                <Typography variant="h6" sx={{ fontWeight: 600, color: theme.palette.text.primary }}>
                  Innovation
                </Typography>
                <Typography variant="caption" sx={{ mt: 1, color: theme.palette.text.secondary , textAlign : 'justify' }}>  
                  We embrace creativity to deliver the best real estate solutions and services to our clients and communities.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  textAlign: 'center',
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 3,
                }}
              >
                <SustainabilityIcon
                  sx={{
                    fontSize: 50,
                    color: theme.palette.primary.main,
                    mb: 2,
                  }}
                />
                <Typography variant="h6" sx={{ fontWeight: 600, color: theme.palette.text.primary }}>
                  Sustainability
                </Typography>
                <Typography variant="caption" sx={{ mt: 1, color: theme.palette.text.secondary , textAlign : 'justify' }}>
                  We focus on creating developments that positively impact the environment and communities.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VisionMissionValues;
