import React, { useState } from 'react';
import { Box, Container, Typography, Tabs, Tab, IconButton, Collapse, Card, CardContent, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { theme } from '../../Theme/theme';


const faqData = [
        {
          "title": "Who is a NRI?",
          "description": "As defined by the Foreign Exchange Management Act of 1999 (FEMA), Non Resident Indian (NRI) is a citizen of India, who stays abroad for employment/carrying on business or vocation outside India or stays abroad under circumstances indicating an intention for an uncertain duration of stay abroad is a non-resident. Non-resident foreign citizens of Indian Origin are treated at par with Non Resident Indian (NRIs)."
        },
        {
          "title": "What documents are required for NRI property purchase?",
          "description": "NRIs need to provide a valid passport, visa, and proof of address. Additionally, a PAN card is required for tax purposes. It's advisable to have these documents ready for a smooth transaction."
        },
        {
          "title": "Can NRIs buy agricultural land in India?",
          "description": "No, the RBI does not allow NRIs to purchase agricultural land or plantation property in India. Under existing regulations, NRIs can’t also purchase farmhouses in India."
        },
        {
          "title": "Can NRIs purchase property jointly with others?",
          "description": "Yes, it is possible for an NRI to purchase property jointly with another NRI. However, any person not authorised to invest in property in India or a resident Indian cannot become a joint holder irrespective of his/her contribution towards the purchase."
        },
        {
          "title": "Can NRIs let out their property in India?",
          "description": "Yes, NRIs can let out their property in India, and they can remit the rent received after paying appropriate Indian taxes on such rent."
        },
        {
          "title": "Can NRIs gift or transfer property to other NRIs?",
          "description": "Yes, NRIs can gift or transfer property to other NRIs, provided it is not a farmhouse, plantation property, or agricultural property."
        },
        {
          "title": "Is a power of attorney required for property transactions?",
          "description": "Yes, if an NRI is not able to come to India, they can give a power of attorney to someone who will execute the deal on their behalf."
        },
        {
          "title": "Can NRIs continue to hold property if they become an NRI?",
          "description": "Yes, a person who owns property in India can continue holding it even if they become an NRI. They can also let out the property or transfer it, subject to certain conditions."
        },
        {
          "title": "What is the tax treatment for NRIs on property income?",
          "description": "NRIs are subject to tax on income earned from property in India. The tax treatment is similar to that for resident Indians, and taxes must be paid before remitting rent income abroad."
        },
        {
          "title": "Can NRIs purchase commercial property?",
          "description": "Yes, NRIs are allowed to purchase commercial property in India. No special permission is needed from the RBI for such purchases."
        },
        {
          "title": "What are the restrictions for NRIs in property investments?",
          "description": "NRIs cannot invest in agricultural land, plantation property, or farmhouses without special permission from the RBI. These restrictions are in place to regulate land usage and ownership."
        }
];      
const NRITabs = () => {
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState<number | null>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleExpandClick = (index: number) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <Box sx={{ backgroundColor:theme.palette.background.default,  display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Container>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="About NRI's" sx={{ fontSize: '0.875rem' }} />
          <Tab label="NRI Documents" sx={{ fontSize: '0.875rem' }} />
          <Tab label="FAQ's" sx={{ fontSize: '0.875rem' }} />
        </Tabs>

        <Divider sx={{ width: '100%', mt: 4 }} />
        <Box sx={{ width: '100%', mt: 2 }}>
          {value === 0 && (
            <Typography variant="body2" fontSize={"10px"} sx={{ textAlign: 'justify'  }}>
              Non-Resident Indians (NRIs), under RBI guidelines, can purchase certain kinds of properties, though other forms may require special permissions. Thus every NRI, interested in buying property in India, must be aware of the provisions related to purchase or ownership of immovable property in India under Foreign Exchange Management Act (FEMA). Persons of Indian Origin (PIOs) and NRIs are always treated the same when it comes to investing in real estate.
              <br /><br />
              The Reserve Bank of India (RBI) has given general permission to NRIs for any residential or commercial property in India. They do not need to seek any special permission from RBI or send any intimation in this regard. NRIs can purchase any number of commercial or residential properties in India. The income tax laws also favour NRIs. In case the NRI is not able to come to India, he/she has the power to give anyone power of attorney who will execute the deal likewise.
              <br /><br />
              However, the RBI does not allow NRIs to purchase agricultural land or plantation property in India. Under existing regulations, NRIs can’t also purchase farmhouses in India. To purchase farmhouses, the NRI has to take special permission, which will be considered on a case-to-case basis. It is possible for an NRI to purchase property jointly with another NRI. However, any person not authorised to invest in property in India or a resident Indian, cannot become a joint holder irrespective of his/her contribution towards the purchase.
              <br /><br />
              If a person who owns a property in India becomes an NRI, he can continue holding the property in his name in India. Interestingly, an NRI will be allowed to hold agricultural land, farmhouse and plantation property if he purchased them before becoming an NRI. He can also let out the property without considering the fact when it was acquired. It is possible to remit the rent received from such properties after appropriate Indian taxes have been paid on such rent. NRIs can also gift or sell any immovable property to a person residing in India. The NRI can even gift or transfer any property to any NRI, provided it’s not a farmhouse, plantation property or agricultural property.
            </Typography>
          )}
          {value === 1 && (
            <Typography variant="body2" sx={{ textAlign: 'justify' , fontSize: '10px' }}>
              Non-Resident Indians (NRIs), under RBI guidelines, can purchase certain kinds of properties, though other forms may require special permissions. Thus every NRI, interested in buying property in India, must be aware of the provisions related to purchase or ownership of immovable property in India under Foreign Exchange Management Act (FEMA). Persons of Indian Origin (PIOs) and NRIs are always treated the same when it comes to investing in real estate.
              <br /><br />
              The Reserve Bank of India (RBI) has given general permission to NRIs for any residential or commercial property in India. They do not need to seek any special permission from RBI or send any intimation in this regard. NRIs can purchase any number of commercial or residential properties in India. The income tax laws also favour NRIs. In case the NRI is not able to come to India, he/she has the power to give anyone power of attorney who will execute the deal likewise.
              <br /><br />
              However, the RBI does not allow NRIs to purchase agricultural land or plantation property in India. Under existing regulations, NRIs can’t also purchase farmhouses in India. To purchase farmhouses, the NRI has to take special permission, which will be considered on a case-to-case basis. It is possible for an NRI to purchase property jointly with another NRI. However, any person not authorised to invest in property in India or a resident Indian, cannot become a joint holder irrespective of his/her contribution towards the purchase.
              <br /><br />
              If a person who owns a property in India becomes an NRI, he can continue holding the property in his name in India. Interestingly, an NRI will be allowed to hold agricultural land, farmhouse and plantation property if he purchased them before becoming an NRI. He can also let out the property without considering the fact when it was acquired. It is possible to remit the rent received from such properties after appropriate Indian taxes have been paid on such rent. NRIs can also gift or sell any immovable property to a person residing in India. The NRI can even gift or transfer any property to any NRI, provided it’s not a farmhouse, plantation property or agricultural property.
            </Typography>
          )}
          {value === 2 && (
            <Box>
              {faqData.map((faq, index) => (
                <Card key={index} sx={{ mb: 2, borderRadius: 2 }}>
                  <CardContent  onClick={() => handleExpandClick(index)}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        {faq.title}
                      </Typography>
                      <IconButton onClick={() => handleExpandClick(index)}>
                        {expanded === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </Box>
                    <Collapse in={expanded === index}>
                      <Typography variant="body2"paragraph sx={{ mt: 2 , textAlign: 'justify', color: '#666' }}>
                        {faq.description}
                      </Typography>
                    </Collapse>
                  </CardContent>
                </Card>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Container>
    </Box>
  );
};

export default NRITabs;
