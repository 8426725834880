// StackAnimation.tsx
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

interface StackAnimationProps {
  images: string[];
}

const StackItem = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  transition: 'opacity 1.5s ease-in-out',
}));

const StackContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 300,
  height: 200,
  overflow: 'hidden',
}));

const StackAnimation: React.FC<StackAnimationProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 1500);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <StackContainer>
      {images.map((image, index) => (
        <StackItem
          key={index}
          sx={{ opacity: index === currentIndex ? 1 : 0 }}
        >
          <img src={image} alt={`Stack ${index}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </StackItem>
      ))}
    </StackContainer>
  );
};

export default StackAnimation;
