import React, { useEffect, useState } from 'react';
import AboutUsMain from './AboutUsTop';
import AboutUSInfo from './AboutUsInfo';
import { FeedImages, house1, house2, house3 } from '../../assets';
import AboutUsServices from './AboutUsServices';
import AboutUsTeam from './AboutUsTeam';
import TopHeading from '../../components/Top/TopHeading';
import LatestNews from '../Dashboard/LatestNews';
import AboutUsVisionMission from './AboutUsVisionMission';
import ExperienceSection from './ExperienceSection';
import Testimonial from '../Dashboard/Testimonial';
import { Box } from '@mui/material';
import ThreeFormsModal from '../../components/ModalForm/ThreeFormsModal';
import Cookies from 'js-cookie';

const About = () => {


  
// Sample data for Features Section
const featureData = [
  {
    title: 'Property Consultation',
    shortDescription: 'Whether you\'re buying or selling, our expert advice ensures you get the best deal.',
    icon: 'build',
    img: house1,
    buttonText: 'Explore Homes',
    active: true,
  },
  {
    title: 'Investment Planning',
    shortDescription: 'We help you identify lucrative investment opportunities and guide you through the process.',
    icon: 'home',
    img: house2,
    buttonText: 'Explore Homes',
    active: false,
  },
  {
    title: 'Market Analysis',
    shortDescription: 'Stay ahead of the curve with our detailed insights into market trends and developments.',
    icon: 'people',
    img: house3,
    buttonText: 'Meet Our Team',
    active: false,
  },
  // {
  //   title: 'Legal Assistance',
  //   shortDescription: 'Navigate the legal complexities of real estate transactions with ease, backed by our experienced team.',
  //   icon: 'people',
  //   img: house3,
  //   buttonText: 'Meet Our Team',
  //   active: false,
  // }
];

// Sample data for Title Section (Features)
const titleSectionData = {
  sectionClasses: 'custom-section-class',
  headingClasses: 'custom-heading-class',
  title: 'Our Services',
  subtitle: 'Expert Guidance for Every Step',
};



const FeedData = [
  {
    image: FeedImages.feed1, // Replace with actual image URLs
    title: '10 Brilliant Ways to Decorate Your Home',
    date: 'August 28, 2024',
    category: 'Interior',
    link: '/news/10-brilliant-ways-to-decorate-your-home', // Replace with actual link
  },
  {
    image: FeedImages.feed2, // Replace with actual image URLs
    title: '5 Tips to Improve Your Outdoor Space',
    date: 'August 30, 2024',
    category: 'Exterior',
    link: '/news/5-tips-to-improve-your-outdoor-space', // Replace with actual link
  },
  {
    image: FeedImages.feed3, // Replace with actual image URLs
    title: 'The Best Furniture for Your Home',
    date: 'September 2, 2024',
    category: 'Furniture',
    link: '/news/the-best-furniture-for-your-home', // Replace with actual link
  },
  {
    image: FeedImages.feed4, // Replace with actual image URLs
    title: 'How to Decorate Your Home on a Budget',
    date: 'September 5, 2024',
    category: 'Budget',
    link: '/news/how-to-decorate-your-home-on-a-budget', // Replace with actual link
  },
  {
    image: FeedImages.feed5, // Replace with actual image URLs
    title: 'The Most Beautiful Architectural Designs',
    date: 'September 10, 2024',
    category: 'Architecture',
    link: '/news/the-most-beautiful-architectural-designs', // Replace with actual link
  },
];

const testimonialData = [
  {
    img: 'testimonial-1.jpg',
    name: 'Anjali Sharma',
    type: 'Homeowner',
    description: 'Realty Yards helped us find our dream home with ease. Their professionalism and attention to detail were exceptional'
  },
  {
    img: 'testimonial-2.jpg',
    name: 'Vikram Singh',
    type: 'Business Owner',
    description: 'The team is knowledgeable and trustworthy. They guided us through a complex commercial deal with great expertise.'
  },
  {
    img: 'testimonial-2.jpg',
    name: 'Anil Kumar',
    type: 'Businessman',
    description: 'From start to finish, they provided exceptional service and helped me find the perfect commercial space'
  },
  {
    img: 'testimonial-2.jpg',
    name: 'Kavita Rao',
    type: 'Doctor',
    description: 'As an NRI, finding the right property in Hyderabad was a challenge until I connected with Realty Yards.'
  },
];
        
  
    return (
        <Box sx={{  py : 1  }}>
            <TopHeading value="About Us" />
            <AboutUSInfo />
            <AboutUsVisionMission />
            <AboutUsServices data={featureData} titleSectionData={titleSectionData} />
            <AboutUsTeam />
            <ExperienceSection />
            <LatestNews articles={FeedData} />
            <Testimonial testimonialData={testimonialData} />
        </Box>
    );
};

export default About;