import React from 'react';
import { Box, Container, Typography, Card, CardContent, CardMedia, Button, IconButton } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ArrowForward, CalendarToday, Label, Person } from '@mui/icons-material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';

interface NewsArticle {
  image: string;
  title: string;
  date: string;
  category: string;
  link: string;
}

interface NewsFeedProps {
  articles: NewsArticle[];
}

const NextArrow = ({ className, style, onClick }: any) => (
  <IconButton
    className={className}
    style={{
      ...style,
      backgroundColor: '#ffffff',
      border: '2px solid #ffffff',
      color: '#da2e31',
      width: '50px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
      position: 'absolute',
      top: '50%',
      zIndex: 1,
      transform: 'translateY(-50%)',
      padding: 0, // Remove any padding from the IconButton to ensure perfect centering
    }}
    onClick={onClick}
  >
    <ArrowForwardIosIcon style={{ fontSize: '24px' }} />
  </IconButton>
);

const PrevArrow = ({ className, style, onClick }: any) => (
  <IconButton
  className={className}
  style={{
    ...style,
    backgroundColor: '#ffffff',
    border: '2px solid #ffffff',
    color: '#da2e31',
    width: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
    position: 'absolute',
    top: '50%',
    zIndex: 1,
    transform: 'translateY(-50%)',
    padding: 0, // Remove any padding from the IconButton to ensure perfect centering
  }}
  onClick={onClick}
>
  {
    className.includes('slick-next') 
      ? <ArrowForwardIosIcon style={{ fontSize: '24px' }} /> 
      : <ArrowBackIosIcon style={{ fontSize: '24px' }} />
  }
</IconButton>
);

const NewsFeed: React.FC<NewsFeedProps> = ({ articles }) => {

  const navigate = useNavigate();
  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{px :{ xs : 2 , sm : 4 , md : 6 , lg : 8 } , py : 4,backgroundColor:'#ffffff'  }}>
      <Container>
        <TitleSection
          sectionClasses="text-center"
          titleSectionData={{
            subTitle: 'News & Blogs',
            title: 'Latest News Feeds',
          }}
        />

        <Box sx={{ mt: 4 }}>
          <Slider {...settings}>
            {articles.map((article, index) => (
            // {/* add gap between the inside of the cards */}

              <Card key={index} sx={{ maxWidth: 345, gap: '20px',boxShadow: '0px 5px 15px rgba(0,0,0,0.3)', borderRadius: '10px'  }}>
                <CardMedia
                  component="img"
                  height="200"
                  width = "100%"
                  image={article.image}
                  alt={article.title}
                  onClick={() => navigate('/projects')}
                  style={{
                    cursor: 'pointer',
                    borderRadius: '10px 10px 0 0',
                  }}
                />
                <CardContent>
                  <Box display="flex" alignItems="center" gap={1} mb={2}>
                    <IconButton size="small">
                      <Person fontSize="small" color="primary" />
                    </IconButton>
                    <Typography variant="caption">Admin</Typography>
                    <IconButton size="small">
                      <Label fontSize="small" color="primary" />
                    </IconButton>
                    <Typography variant="caption">{article.category}</Typography>
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 , textAlign: 'left' }}>
                    {article.title}
                  </Typography>
                  <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <CalendarToday fontSize="small" />
                      <Typography variant="caption">{article.date}</Typography>
                    </Box>
                    <Typography variant="body2" color="primary" sx={{ fontWeight: 600 , cursor : 'pointer' }}>
                      Read More
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Slider>
        </Box>
      </Container>
    </Box>
  );
};

const TitleSection = ({
  sectionClasses,
  titleSectionData,
}: {
  sectionClasses?: string;
  titleSectionData: {
    subTitle?: string;
    title: string;
  };
}) => (
  <Box className={sectionClasses} sx={{ textAlign: 'center' }}>
    {titleSectionData.subTitle && (
      <Typography variant="body1" color="primary" sx={{ fontWeight: 500, mb: 1 }}>
        {titleSectionData.subTitle}
      </Typography>
    )}
    <Typography variant="h3" sx={{ fontWeight: 700 }}>
      {titleSectionData.title}
    </Typography>
  </Box>
);

export default NewsFeed;
