import React from 'react';
import { Box, Typography } from '@mui/material';

const ContactUsIFrame: React.FC = () => {
    return (
        <Box sx={{ padding: { xs: 2, sm: 4, md: 6, lg: 6 } , alignContent:"center" }}>
        {/* <Box
          sx={{
            background: '#da2e31',
            borderRadius: '15px',
            width: '4px',
            height: '25px',
            marginRight: 1,
          }}
        /> */}
           <Typography variant="h6" sx={{ fontWeight: 'bold' }}> Location Map</Typography>
                           
            <Box sx={{ width: '100%', height: { xs: '300px', sm: '300px', md: '350px', lg: '400px' } , border: '2px solid #cccccc' }}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13426.905708856544!2d78.34002385833121!3d17.38409136243612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb95e234e2bdc5%3A0x3016708ca832dbd1!2sBIRLA%20OPENMINDS%20PRESCHOOL%20KOKAPET!5e0!3m2!1sen!2sin!4v1725010132243!5m2!1sen!2sin"
                    title="Contact Us"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                />
            </Box>

        </Box>
    );
};

export default ContactUsIFrame;
