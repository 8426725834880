// src/components/AboutUSInfo.tsx

import React from 'react';
import { Box, Typography, Grid, useTheme, Divider } from '@mui/material';
import { Aboutus1, home1 } from '../../assets'; // Ensure the image path is correct

const AboutUSInfo: React.FC = () => {
  const theme = useTheme();

  return (
    <>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' }, // Stack on mobile, row on larger screens
          px: { xs: 2, sm: 4, md: 6, lg: 8 },
          py: 4,
          alignItems: 'center', // Center items vertically
          justifyContent: 'center', // Center items horizontally
        }}
      >
        {/* Left Side - Image */}
        <Box
          sx={{
            flex: 1,
            paddingRight: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center', // Center image on mobile
            mb: { xs: 2, sm: 0 }, // Margin bottom on mobile
          }}
        >
          <img
            src={Aboutus1}
            alt="Real Estate"
            style={{
              width: '100%', // Full width for responsiveness
              maxWidth: '400px', // Limit maximum width
              height: 'auto', // Keep aspect ratio
              borderRadius: theme.shape.borderRadius,
              objectFit: 'cover',
            }}
          />
        </Box>

        {/* Right Side - Text Content */}
        <Box sx={{ flex: 1.4, display: 'flex', position: 'relative' }}>
          <Box
            sx={{
              paddingLeft: theme.spacing(2),
              paddingTop: theme.spacing(2),
              paddingBottom: theme.spacing(2),
              zIndex: 1,
              textAlign: 'left',
            }}
          >
            {/* About Us Badge */}
            <Box
              sx={{
                marginBottom: theme.spacing(2),
                backgroundColor: '#fc585b',
                padding: theme.spacing(1),
                borderRadius: '12px',
                width: 'fit-content',
              }}
            >
              <Typography variant="body1" color="#ffffff" sx={{ fontWeight: 'bold' }}>
                Vision & Mission
              </Typography>
            </Box>

            {/* Heading */}
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
              Defining the Future: Our Vision & Mission
            </Typography>

            {/* Description */}
            <Typography variant="body2" paragraph sx={{ textAlign: 'justify' }}>
              {/* Add a brief description here if needed */}
            </Typography>

            {/* Our Services Component */}
            <Box sx={{ marginTop: theme.spacing(2) }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 700 }}>
                Our Vision
              </Typography>
              <Typography variant="body2">
                To be Hyderabad's premier real estate company, known for quality, innovation, and sustainable communities.
              </Typography>
            </Box>

            <Box sx={{ marginTop: theme.spacing(2) }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 700 }}>
                Our Mission
              </Typography>
              <Typography variant="body2">
                To provide exceptional real estate solutions through integrity and expertise, creating lasting value for our clients and communities.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AboutUSInfo;
