import logo from './images/logo.png';
import logo2 from './images/logo2.png';
import image from './images/image.png';
import logo1 from './images/logo.jpg';
import temp from './images/temp.png';
import section2 from './images/section2.jpg';
import house1 from './images/house1.png';
import house2 from './images/house2.png';
import house3 from './images/house3.png';
import map from './images/map.png';



//Aminities
import bedking from './images/Aminities/bedking.png';
import books from './images/Aminities/books.png';
import car from './images/Aminities/car.png';
import shield from './images/Aminities/shield.png';
import playground from './images/Aminities/playground.png';
import stethoscope from './images/Aminities/stethoscope.png';
import swimming from './images/Aminities/swimming.png';
import homelove from './images/Aminities/homelove.png';


//Feed
import feed1 from './images/Feed/feed1.png';
import feed2 from './images/Feed/feed2.jpg';
import feed3 from './images/Feed/feed3.png';
import feed4 from './images/Feed/feed4.jpg';
import feed5 from './images/Feed/feed5.png';
import nodata from './images/nodata.jpg';
import giridhars from './images/Feed/Giridharis.png';
import moon from "./images/Feed/moon.png";
import nivasa from './images/Feed/nivasa.png'


//Contact Us
import addressimage from './images/ContactUs/addressimage.png';
import emailimage from './images/ContactUs/emailimage.png';
import mobileimage from './images/ContactUs/mobileimage.png';

import MainVideo from './Videos/MainVideo.mp4';
//WEBSITE.mp4
import person from './images/person.jpg';

import Aboutus1 from './images/aboutus1.png';
import home1 from './images/home1.png';

import galleryimage from './images/galleryimage.jpg';
import usericon from './images/usericon.png';

//PDF

import acasapdf from "./PDF/acasa brochure2.pdf";
import giridharipdf from "./PDF/Giridhari's Prospera County Brochure.pdf";
import MGpdf from './PDF/MG.pdf';
import nivasapdf from "./PDF/Nivasa Brochure.pdf";
import visionspdf from "./PDF/Vision's Arsha Brochure.pdf";




export const PDF = {
    acasapdf,
    giridharipdf,
    MGpdf,
    nivasapdf,
    visionspdf,
};


export { logo, image, logo1 , temp , section2 , house1 , house2 , house3 , map  , Aboutus1, home1, logo2, person , galleryimage, usericon};

export { MainVideo };

export const AminitiesImages = {
    bedking,
    books,
    car,
    shield,
    playground,
    stethoscope,
    swimming,
    homelove,
};


//Feed
export const FeedImages = {
    feed1,
    feed2,
    feed3,
    feed4,
    feed5,
    nodata,
    giridhars,
    moon,
    nivasa,
};

export const ContactUsImages = {
    addressimage,
    emailimage,
    mobileimage,
};
