import { http } from "../http";

export const authLogin = (data: any) => {
  return http.post("/auth/login", data);
};

// EmailForm submission
export const emailForm = (data: any) => {
  return http.post("/emailform", data);
}


//userprofile-id
export const getUserProfile = (id: string) => {
  return http.get(`/users/profile/${id}`);
};




//Projects
export const getProjects = () => {
  return http.get("/projects");
};

export const getProjectData = (id: string) => {
  return http.get(`/projects/${id}`);
};


// Users  

export const getUsers = () => {
  return http.get("/users");
};

export const getUser = (id: string) => {
  return http.get(`/users/${id}`);
};

export const addUser = (data: any) => {
  return http.post("/users", data);
};

export const updateUser = (userId: number, data: any) => {
  return http.put(`/users/${userId}`, data);
};
