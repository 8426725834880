import React from 'react';
import { Box, Card, CardContent, CardMedia, Typography, Grid, IconButton, Divider, Pagination, Select, MenuItem } from '@mui/material';
import { LocationOn, Favorite } from '@mui/icons-material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { useNavigate } from 'react-router-dom';
import ShareIcon from '@mui/icons-material/Share';
import ShareButton from '../../components/Share/Share';
import { FeedImages, usericon } from '../../assets';

interface Project {
  _id: string;
  builderImage: string;
  builderName: string;
  builderOccupation: string;
  projectName: string;
  projectLocation: string;
  projectBHK: number[];
  unitsApartments: string;
  size: string;
  approvals: string;
  category: string;
  amenities: string[];
  locationHighlights: string;
  floorPlans: string[] | null;
  projectElevations: string[] | null;
  propertyType: string;
  priceRange: string;
  bhk: number;
  bath: number;
  createdAt: string;
  updatedAt: string;
  price: number;
  squareFeet: number;
  projectImages: string[];
}



interface ProjectsCardProps {
  paginatedData: Project[];
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  itemsPerPage: number;
  handleChangeItemsPerPage: (value: number) => void;
}

const ProjectsCard: React.FC<ProjectsCardProps> = ({
  paginatedData,
  currentPage,
  totalPages,
  setCurrentPage,
  itemsPerPage,
  handleChangeItemsPerPage,
}) => {
  const navigate = useNavigate();

  const formatNumberIndian = (num: number) => {
    return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(num);
  };

  const handleCardClick = (_id: string) => {
    navigate(`/projects/${_id}`);
  };

  const urlToShare = "http://realtyyards.com/projects";
  const titleToShare = "Check out this amazing website!";

  return (
    <Box>
            {paginatedData.length === 0 ? (
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <Box sx={{ width: { xs: '65%', sm: '40%', md: '30%', lg: '25%' }, margin: '0 auto' }}>
                        <img src={FeedImages.nodata} alt="No Properties Available" style={{ width: "100%", height: 'auto' }} />
                    </Box>
                    <Typography variant="h4" align="center" sx={{ mt: 2, color: 'text.secondary', fontWeight: '600' }}>
                        No Properties Available
                    </Typography>
                </Box>
            ) : (
        <>
          <Grid container spacing={4}>
            {paginatedData.map((project) => (
              <Grid item xs={12} key={project._id}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': { 
                      boxShadow: '0px 5px 15px rgba(0,0,0,0.5)',
                      transform: 'translateY(-10px)',
                    },
                  }}
                  
                >
                  {/* Left Part: Image */}
                  <Box sx={{ height: { xs: '200px', md: '230px' }, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CardMedia
                    component="img"
                    sx={{
                      width: { xs: '100%', md: '230px' },
                      height: '100%',
                      objectFit: 'cover', // ensures the image covers the box entirely
                      objectPosition: 'center', // centers the image inside the box
                    }}
                    image={`https://api.realtyyards.com/uploads/${project.projectImages[0]}`}
                    alt={project.projectName}
                    onClick={() => handleCardClick(project._id)}
                  />
                </Box>

                  {/* Right Part: Content */}
                  <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1,px:1.2  }}>
                    <CardContent sx={{ flex: '1 0 auto', padding: 2 }}>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="caption" color="primary" sx={{ fontWeight: '500' }} onClick={() => handleCardClick(project._id)}>
                          {project.propertyType}
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 600, mt:0.5, color: 'primary.main' }}>
                          <b> ₹ {formatNumberIndian(project.price)}</b> INR
                        </Typography>
                      </Box>

                      <Typography variant="body1" sx={{ fontWeight: 700, mb: 0.5, textAlign: 'left' }} onClick={() => handleCardClick(project._id)}>
                        {project.projectName}
                      </Typography>

                      <Box display="flex" alignItems="center" gap={1} mb={1}>
                        <LocationOn fontSize="small" color="primary" />
                        <Typography variant="caption" color="text.secondary">
                          {project.projectLocation}
                        </Typography>
                      </Box>

                      {/* <Typography variant="caption" color="text.secondary" mb={0.5} sx={{ textAlign: 'left' }} paragraph>
                        <b>{project.bhk}</b> Bed <b>{project.bhk}</b> Bath <b>{project.squareFeet}</b> Square Ft.
                      </Typography> */}

                      <Divider sx={{ mb: 1 }} />

                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center" gap={2}>
                          <Box
                            sx={{
                              width: '40px',
                              height: '40px',
                              borderRadius: '50%', 
                              overflow: 'hidden',
                            }}
                          >
                            <img
                              src={usericon}
                              alt="Builder"
                              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                          </Box>
                          <Typography variant="caption" color="text.secondary" sx={{ textAlign: 'left' }}>
                            <b>{project.builderName}</b> <br /> {project.builderOccupation}
                          </Typography>
                        </Box>

                        <Box display="flex" alignItems="center" gap={1}>
                          <Box display="flex" alignItems="center" gap={1}>
                            <IconButton
                              size="small"
                              sx={{
                                backgroundColor: '#f5f5f5',
                                height: '35px',
                                width: '35px',
                                borderRadius: '2px',
                                '&:hover': { backgroundColor: '#da2e3170', color: 'white' },
                              }}
                            >
                              <OpenInFullIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              sx={{
                                backgroundColor: '#f5f5f5',
                                height: '35px',
                                width: '35px',
                                borderRadius: '2px',
                                '&:hover': { backgroundColor: '#da2e3170', color: 'white' },
                              }}
                            >
                              <Favorite />
                            </IconButton>
                          </Box>
                          <Box display="flex" alignItems="center" gap={1}>
                            <IconButton
                              size="small"
                              sx={{
                                backgroundColor: '#f5f5f5',
                                height: '35px',
                                width: '35px',
                                borderRadius: '2px',
                                '&:hover': { backgroundColor: '#da2e3170', color: 'white' },
                              }}
                            >
                              {/* <ShareIcon /> */}
                              <ShareButton shareUrl={`${urlToShare}/${project._id}`} shareTitle={titleToShare} />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* Pagination and Rows per Page */}
          <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="20px">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(event, value) => setCurrentPage(value)}
                  shape="rounded"
                  sx={{
                    "& .MuiPaginationItem-root": {
                      color: 'primary.main',
                      height: "27px",
                      minWidth: "27px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      margin: "0 4px",
                    },
                    "& .MuiPaginationItem-page.Mui-selected": {
                      backgroundColor: 'primary.main',
                      color: 'primary.contrastText',
                    },
                    "& .MuiPaginationItem-page.Mui-selected:hover": {
                      backgroundColor: 'primary.main',
                      color: 'primary.contrastText',
                    },
                    "& .Mui-selected": {
                      backgroundColor: 'primary.main',
                      color: 'primary.contrastText',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                Rows per page:
                <Select
                  value={itemsPerPage}
                  onChange={(e) => handleChangeItemsPerPage(Number(e.target.value))}
                  variant="outlined"
                  style={{
                    width: "auto",
                    height: "25px",
                    background: "#FFFFFF",
                    borderRadius: "5px",
                    textAlign: "center",
                    lineHeight: "20px",
                    marginLeft: "10px",
                    outline: "none",
                    boxShadow: "none",
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={150}>150</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ProjectsCard;
