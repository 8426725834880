import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
  FormControl,
  useMediaQuery,
  Theme,
  Slider,
  Popover,
  ClickAwayListener,
} from "@mui/material";
import { ArrowDropDown, Search, Apps, ViewModule } from "@mui/icons-material";
import debounce from "lodash/debounce";
import { useQuery } from "react-query";
import { getProjects } from "../../api/services"; 
import ProjectsGrid from "./ProjectsGrid";
import ProjectsCard from "./ProjectsCard";
import { FeedImages, person } from "../../assets";
import TableRowsIcon from '@mui/icons-material/TableRows';
import TopHeading from "../../components/Top/TopHeading";
import axios from "axios";

interface Project {
  _id: string;
  builderImage: string;
  builderName: string;
  builderOccupation: string;
  projectName: string;
  projectLocation: string;
  projectBHK: number[];
  unitsApartments: string;
  size: string;
  approvals: string;
  category: string;
  amenities: string[];
  locationHighlights: string;
  floorPlans: string[] | null;
  projectElevations: string[] | null;
  propertyType: string;
  priceRange: string;
  bhk: number;
  bath: number;
  createdAt: string;
  updatedAt: string;
  price: number;
  squareFeet: number;
  projectImages: string[];
}



const projectsData = {
  data: {
    data: [
      {
        id: 1,
        builderImage: person,
        builderName: "John Doe",
        builderOccupation: "Real Estate Developer",
        projectName: "Giridhari Prospera Villas",
        projectLocation: "Kismatpur, Hyderabad",
        projectBHK: [4, 5],
        unitsApartments: "121 Units",
        size: "4020 - 6150",
        approvals: "P02400002730",
        category: "Ultra luxury triplex Villas",
        amenities: [
          "Clubhouse",
          "Swimming Pool",
          "Banquets",
          "Multi-purpose Hall",
          "Business Lounge",
          "Cafeteria & Library",
          "Poolside Party Lawn & Gym",
          "Yoga / Aerobics Studio",
          "Children Play Area",
          "Tennis Court",
          "Basketball Court with Viewing Gallery",
          "Badminton Court",
          "Squash Court",
          "Meditation / Exercise Decks",
          "Table Tennis & Billiards",
          "Preview Theatre",  
          "Library",
          "Spa",
          "Gymnasium",
          "Amphitheater"  
        ],
        locationHighlights: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30469.190684033892!2d78.3968348!3d17.3324844!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9792c3fa2a61%3A0x8e17ec40b3442de9!2sProspera%20County%20%7C%20Giridhari%20Constructions!5e0!3m2!1sen!2sin!4v1726484061997!5m2!1sen!2sin",
        floorPlans: null,
        projectElevations: null,
        propertyType: "Villa",
        priceRange: "High",
        bhk: 5,
        bath: 5,
        createdAt: "2021-10-10",
        updatedAt: "2021-10-10",
        price: 8000,
        squareFeet: 6150,
        projectImage: [FeedImages.giridhars, FeedImages.feed1, FeedImages.feed2, FeedImages.feed3, FeedImages.feed4, FeedImages.feed5],
      },
      {
        id: 2,
        builderImage: person,
        builderName: "Jane Smith",
        builderOccupation: "Apartment Developer",
        projectName: "Visions Arsha Apartments",
        projectLocation: "Tellapur Hyderabad",
        projectBHK: [2, 3, 3.5],
        unitsApartments: "1561 Apartments",
        size: "1390 - 2700",
        approvals: "P01100005595",
        category: "Apartments",
        amenities: [
          "Club House",
          "Cafeteria/Food Court",
          "Bar/Lounge",
          "Cycling & Jogging Track",
          "Swimming Pool",
          "Security",
          "Bank & ATM",
          "Banquet Hall",
          "Guest Accommodation",
          "Gymnasium",
          "Indoor Games Room",
          "Aerobics Room"
        ],
        locationHighlights: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.2575320651604!2d78.28095449999999!3d17.4473837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcbed5a1a3bef3f%3A0x90969c298b4674b2!2sVision%20Arsha!5e0!3m2!1sen!2sin!4v1726484379496!5m2!1sen!2sin",
        floorPlans: null,
        projectElevations: null,
        propertyType: "Apartment",
        priceRange: "Medium",
        bhk: 3.5,
        bath: 3,
        createdAt: "2022-02-15",
        updatedAt: "2022-03-01",
        price: 7500,
        squareFeet: 2700,
        projectImage: [FeedImages.feed2, FeedImages.feed1, FeedImages.feed3, FeedImages.feed4, FeedImages.feed5],
      },
      {
        id: 3,
        builderImage: person,
        builderName: "Bob Johnson",
        builderOccupation: "Residential Developer",
        projectName: "Moon Glade Apartments",
        projectLocation: "Narsingi, Hyderabad",
        projectBHK: [3, 4],
        unitsApartments: "2400 Apartments",
        size: "1400-3950",
        approvals: "",
        category: "Apartments",
        amenities: [
          "Gymnasium",
          "Tennis Court",
          "Kids Play Area",
          "24 Hours Concierge",
          "Swimming Pool",
          "Kids' Play Areas",
          "24 x 7 Security",
          "CCTV Security",
          "Badminton Court",
          "Jogging / Cycle Track",
          "Clubhouse",
          "Cleaning Services"
        ],
        locationHighlights: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.6384194044977!2d78.3490307!3d17.381123499999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb958a3deb39c5%3A0xcbbcde5e0e5ec6b3!2sMoonGlade%20Apartments!5e0!3m2!1sen!2sin!4v1726484437780!5m2!1sen!2sin",
        floorPlans: null,
        projectElevations: null,
        propertyType: "Apartment",
        priceRange: "Medium",
        bhk: 4,
        bath: 4,
        createdAt: "2022-05-10",
        updatedAt: "2022-06-15",
        price: 7000,
        squareFeet: 3950,
        projectImage: [FeedImages.moon, FeedImages.feed1, FeedImages.feed2, FeedImages.feed3, FeedImages.feed4, FeedImages.feed5],
      },
      {
        id: 4,
        builderImage: person,
        builderName: "Alice Brown",
        builderOccupation: "Eco-Friendly Developer",
        projectName: "Acasa Apartments",
        projectLocation: "Kokapet, Hyderabad",
        projectBHK: [3.5],
        unitsApartments: "102 Apartments",
        size: "3520 - 3750",
        approvals: "P02400006471",
        category: "Apartments",
        amenities: [
          "Swimming Pool",
          "Vastu Compliant",
          "Fire Sprinklers",
          "Landscaping & Tree",
          "Water Conservation",
          "Electrification (Transformer, Solar Energy)",
          "Community Buildings",
          "24X7 Water Supply",
          "Car Parking",
          "Closed Car Parking",
          "High-tech alarm system"
        ],
        locationHighlights: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30460.501940407583!2d78.3228352!3d17.3847611!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb95d25d3c466b%3A0xfca51311ea94361a!2sACASA%20Hirise%20Towers!5e0!3m2!1sen!2sin!4v1726483611389!5m2!1sen!2sin",
        floorPlans: null,
        projectElevations: null,
        propertyType: "Apartment",
        priceRange: "High",
        bhk: 3.5,
        bath: 4,
        createdAt: "2023-01-05",
        updatedAt: "2023-02-15",
        price: 8000,
        squareFeet: 3750,
        projectImage: [FeedImages.feed3, FeedImages.feed2, FeedImages.feed4, FeedImages.feed1, FeedImages.feed5],
      },
      {
        id: 5,
        builderImage: person,
        builderName: "Chris Evans",
        builderOccupation: "Luxury Developer",
        projectName: "Lacasa Villas",
        projectLocation: "TCS ADIBATLA, Hyderabad",
        projectBHK: [4],
        unitsApartments: "182 Units",
        size: "4054 - 8564",
        approvals: "P02400005578",
        category: "Villas",
        amenities: [
          "24Hrs Water Supply",
          "Banquet Hall",
          "Cricket Court",
          "Gym",
          "Jogging Track",
          "Super Market",
          "Badminton Court",
          "CCTV Cameras",
          "Entrance Gate With Security",
          "Club House",
          "24Hrs Backup Electricity",
          "Fire Safety",
          "Landscaped Garden"
        ],
        locationHighlights: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3810.9698789177946!2d78.54924600918741!3d17.220250483570403!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcba56ab52bd063%3A0x389012b9e212242c!2sLACASA%20by%20E-Infra!5e0!3m2!1sen!2sin!4v1726486021926!5m2!1sen!2sin",
        floorPlans: null,
        projectElevations: null,
        propertyType: "Villa",
        priceRange: "Very High",
        bhk: 4,
        bath: 5,
        createdAt: "2023-03-20",
        updatedAt: "2023-04-10",
        price: 6500,
        squareFeet: 8564,
        projectImage: [FeedImages.feed3, FeedImages.feed2, FeedImages.feed3, FeedImages.feed4, FeedImages.feed5],
      },
      {
        id: 6,
        builderImage: person,
        builderName: "David Miller",
        builderOccupation: "Urban Developer",
        projectName: "Nivasa Apartment.",
        projectLocation: "Kollure, Tellapur, Hyderabad",
        projectBHK: [2, 3],
        unitsApartments: "526 Apartments",
        size: "1375 - 2205",
        approvals: "P01100007243",
        category: "Apartments",
        amenities: [
          "Waiting Lounge",
          "Tennis Court",
          "Spa",
          "Swimming Pool",
          "Vastu Compliant",
          "Landscaping & Tree",
          "Business Suites",
          "Gymnasium",
          "Fire Fighting System",
          "Car Parking",
          "Squash Court"
        ],
        locationHighlights: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.9970039977666!2d78.26092330000002!3d17.4598575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcbedbb0555f1cf%3A0xaf0c520c520eff74!2sELEGANT%20NIVASA%20by%20e-infra!5e0!3m2!1sen!2sin!4v1726484522333!5m2!1sen!2sin",
        floorPlans: null,
        projectElevations: null,
        propertyType: "Apartment",
        priceRange: "Medium",
        bhk: 3,
        bath: 3,
        createdAt: "2023-05-15",
        updatedAt: "2023-06-10",
        price: 8000,
        squareFeet: 2205,
        projectImage: [FeedImages.nivasa, FeedImages.feed1, FeedImages.feed2, FeedImages.feed3, FeedImages.feed4, FeedImages.feed5],
      },
      {
        id: 7,
        builderImage: person,
        builderName: "Eva Green",
        builderOccupation: "Prelaunch Specialist",
        projectName: "Skyven Kokapet",
        projectLocation: "Gandipet, Hyderabad",
        projectBHK: [2, 3],
        unitsApartments: "To Be Decided",
        size: "To Be Decided",
        approvals: "To Be Decided",
        category: "Prelaunch Apartments",
        amenities: [
          // List amenities once available
        ],
        locationHighlights: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.4426636574644!2d78.33854700919271!3d17.390531483428358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb94f6804674e5%3A0x957dc919014b432b!2sKokapet%20Rd%2C%20Kokapet%2C%20Hyderabad%2C%20Narsingi%2C%20Telangana%20500075!5e0!3m2!1sen!2sin!4v1726486224201!5m2!1sen!2sin",
        floorPlans: null,
        projectElevations: null,
        propertyType: "Apartment",
        priceRange: "TBD",
        bhk: 3,
        bath: 2,
        createdAt: "2023-08-01",
        updatedAt: "2023-08-25",
        price: 7500,
        squareFeet: 0,
        projectImage: [FeedImages.feed4, FeedImages.feed2, FeedImages.feed3, FeedImages.feed4, FeedImages.feed5],
      },
      {
        id: 8,
        builderImage: person,
        builderName: "Sophia Anderson",
        builderOccupation: "Apartment Developer",
        projectName: "Aparna Zenon",
        projectLocation: "Puppalaguda, Hyderabad",
        projectBHK: [2, 3],
        unitsApartments: "3664 Apartments",
        size: "1326 - 2257",
        approvals: "P02400003722",
        category: "Apartments",
        amenities: [
          "Club House",
          "Power Back Up",
          "Lift",
          "Security",
          "Park",
          "Reserved Parking",
          "Intercom Facility",
          "Gymnasium",
          "Indoor Games Room",
          "Aerobics Room",
          "Piped Gas",
          "Rain Water Harvesting"
        ],
        locationHighlights: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.0930126239464!2d78.35682300919322!3d17.407323383414365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb95c7d49eda5d%3A0x6d7a09a7be88421f!2sAparna%20Zenon!5e0!3m2!1sen!2sin!4v1726486104539!5m2!1sen!2sin",
        reviews: [
          {
              "rating": 5,
              "message": "This venture is perfect",
              "name": "srinivas",
              "email": "srinivasnani005@gmail.com",
              "_id": "66ea7be777bf6556b04b70c8",
              "createdAt": "2024-09-12T07:06:15.163Z",
              "updatedAt": "2024-09-12T07:06:15.163Z"
          },
          {
              "rating": 4.7,
              "message": "This venture is perfect",
              "name": "Hussein",
              "email": "hussein@gmail.com",
              "_id": "66ea7c1b77bf6556b04b70ce",
              "createdAt": "2024-09-18T07:07:07.248Z",
              "updatedAt": "2024-09-18T07:07:07.248Z"
          }
      ],
        floorPlans: null,
        projectElevations: null,
        propertyType: "Apartment",
        priceRange: "Medium",
        bhk: 3,
        bath: 3,
        createdAt: "2023-09-10",
        updatedAt: "2023-09-20",
        price: 7000,
        squareFeet: 2257,
        projectImage: [FeedImages.feed1, FeedImages.feed2, FeedImages.feed3, FeedImages.feed4, FeedImages.feed5],
      },
    ],
  },
};



const Projects: React.FC = () => {
  const { data: tempData, isLoading } = useQuery("getProjectData", getProjects);

  

  console.log("tempData", tempData?.data);
  
  const data: Project[] = tempData?.data || [];
  // const data: Project[] = projectsData?.data?.data || [];

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterPropertyType, setFilterPropertyType] = useState<string[]>([]);
  const [filterAmenities, setFilterAmenities] = useState<string[]>([]);
  const [filterPriceRange, setFilterPriceRange] = useState<string[]>([]);
  const [filterBhk, setFilterBhk] = useState<number[]>([]);
  const [sortOption, setSortOption] = useState<string>("newest");
  const [itemsPerPage, setItemsPerPage] = useState<number>(50);
  const [viewMode, setViewMode] = useState<"grid" | "card">("grid");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [priceRange, setPriceRange] = useState<number[]>([0, 100000]); // Default range
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);


  const propertyTypeOptions: string[] = Array.from(new Set(data.map((project: Project) => project.propertyType)));
  const amenitiesOptions: string[] = Array.from(new Set(data.flatMap((project: Project) => project.amenities)));
  const priceRangeOptions = ["Low", "Medium", "High"];
  const bhkOptions = [2,3,4,5]

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "price-range-popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

   const minPrice = Math.min(...data.map(project => project.price));
   const maxPrice = Math.max(...data.map(project => project.price));


   useEffect(() => {
    setPriceRange([minPrice, maxPrice]);
  }, [minPrice, maxPrice]);


  const handlePriceRangeChange = (event: any, newValue: number | number[]) => {
    setPriceRange(newValue as number[]);
  };


  const handleSearch = debounce((value: string) => {
    setSearchTerm(value);
  }, 600);

  const handleChangeItemsPerPage = (value: number) => {
    setItemsPerPage(value);
  };

  const handleSortChange = (event: any) => {
    setSortOption(event.target.value);
  };

  const handlePropertyTypeChange = (event: any) => {
    const value = event.target.value;
    setFilterPropertyType(typeof value === "string" ? value.split(",") : value);
  };

  const handleAmenitiesChange = (event: any) => {
    const value = event.target.value;
    setFilterAmenities(typeof value === "string" ? value.split(",") : value);
  };

  // const handlePriceRangeChange = (event: any) => {
  //   const value = event.target.value;
  //   setFilterPriceRange(typeof value === "string" ? value.split(",") : value);
  // };

  const handleBhkChange = (event: any) => {
    const value = event.target.value;
    setFilterBhk(typeof value === "string" ? value.split(",").map(Number) : value);
  };


  const clearFilters = () => {
    setFilterPropertyType([]);
    setFilterAmenities([]);
    setPriceRange([minPrice, maxPrice]);
    setFilterBhk([]);
  };

  const filteredDataMemo: Project[] = useMemo(() => {
    return data.filter((project: Project) =>
      (!searchTerm ||
        project.projectName.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (filterPropertyType.length === 0 ||
        filterPropertyType.includes(project.propertyType)) &&
      (filterAmenities.length === 0 ||
        project.amenities.some((amenity) => filterAmenities.includes(amenity))) &&
      (filterBhk.length === 0 || filterBhk.includes(project.bhk)) &&
      (project.price >= priceRange[0] && project.price <= priceRange[1]) // Price range filter
    );
  }, [data, searchTerm, filterPropertyType, filterAmenities, priceRange, filterBhk]);

  const sortedData = useMemo(() => {
    switch (sortOption) {
      case "newest":
        return [...filteredDataMemo].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      case "oldest":
        return [...filteredDataMemo].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
      default:
        return filteredDataMemo;
    }
  }, [filteredDataMemo, sortOption]);

  const totalItems: number = sortedData.length;
  const totalPages: number = Math.ceil(totalItems / itemsPerPage);
  const paginatedData = sortedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  useEffect(() => {
    setCurrentPage(1);
  }, [filteredDataMemo, itemsPerPage]);

  if (isLoading) return <LinearProgress />;

  return (
    <Box sx={{ 
      px :{ xs : 2 , sm : 3 , md : 5 , lg : 6 } , 
      py : 1 ,
      flexGrow: 1,
    }}>
      <TopHeading value="Projects" />

      {/* Heading for as :"Uncover Our Prestigious Projects: Excellence in Every Detail" */}
      {/* <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          justifyContent: 'center',
          textAlign: 'left',
          px:2,
      }}>
          <Typography variant="h4" sx={{ fontWeight: 700, color: '#1D1D1D' }}>
              Uncover Our Prestigious Projects
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 500, color: 'primary.main' }}>
              Excellence in Every Detail
          </Typography>
      </Box> */}

          { isMobile ? <></> :
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "25%",
                    position: "fixed",       
                    bottom: 0,               
                    right: 0,               
                    margin: "25px",         
                    zIndex: 1000,           
                  }}
                >
                  <ToggleButtonGroup
                    value={viewMode}
                    exclusive
                    onChange={(e, newViewMode) => newViewMode && setViewMode(newViewMode)}
                    sx={{
                      marginLeft: { xs: 0, sm: "auto" },
                      mt: { xs: "0px", sm: 0 },
                    }}
                  >
                    <ToggleButton
                      value="grid"
                      sx={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: viewMode === "grid" ? "#da2e31" : "#ffffff",
                        color: viewMode === "grid" ? "#ffffff" : "#000000",
                        borderRadius: "10px 0 0 10px",
                        border: `1px solid ${theme.palette.primary.main}`,
                        borderRight: "none",
                        "&.Mui-selected": {
                          backgroundColor: "#da2e31",
                          color: "#ffffff",
                          "&:hover": {
                            backgroundColor: "#da2e31",
                            color: "#ffffff",
                          },
                        },
                      }}
                    >
                      <Apps />
                    </ToggleButton>
                    <ToggleButton
                      value="card"
                      sx={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: viewMode === "card" ? "#da2e31" : "#ffffff",
                        color: viewMode === "card" ? "#ffffff" : "#000000",
                        borderRadius: "0 10px 10px 0",
                        border: `1px solid ${theme.palette.primary.main}`,
                        borderLeft: "none",
                        "&.Mui-selected": {
                          backgroundColor: "#da2e31",
                          color: "#ffffff",
                          "&:hover": {
                            backgroundColor: "#da2e31",
                            color: "#ffffff",
                          },
                        },
                      }}
                    >
                      <TableRowsIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              }



      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{
              // marginTop: { xs: "10px", sm: "20px" },
        //   marginBottom: { xs: "10px", sm: "20px" },
        }}
      >
        {/* First Row: Search and View Mode Toggle */}
        <Grid item xs={12}>
        <Card
          sx={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #1212121A",
            borderRadius: "10px",
            padding: { xs: "10px", sm: "20px" },
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            justifyContent: { xs: "center", sm: "space-between" },
            gap: { xs: 2, sm: 0 }, // added to remove unnecessary gaps
          }}
        >
          {/* Search Input */}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={3}>
              <TextField
                placeholder="Search By Project Title"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small">
                        <Search fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    height: "40px",
                    boxShadow: "0px 6px 14px #36408D08",
                    border: "1px solid #2B2D221C",
                    borderRadius: "10px",
                    fontSize: "16px",
                    textAlign: "left",
                  },
                }}
              />
            </Grid>

            {/* Property Type */}
            <Grid item xs={6} md={2.2}>
              <FormControl variant="outlined" size="small" fullWidth>
                <Select
                  multiple
                  value={filterPropertyType}
                  onChange={handlePropertyTypeChange}
                  IconComponent={ArrowDropDown}
                  displayEmpty
                  renderValue={(selected) =>
                    selected.length > 0 ? (
                      <Box display="flex" alignItems="center">
                        Property Type
                        <Box
                          component="span"
                          borderRadius="50%"
                          color="#FFFFFF"
                          fontSize="10px"
                          marginLeft="5px"
                          width="15px"
                          height="15px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          style={{
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          {selected.length}
                        </Box>
                      </Box>
                    ) : (
                      "Property Type"
                    )
                  }
                  style={{
                    width: "100%",
                    height: "40px",
                    border: "1px solid #1212121A",
                    borderRadius: "10px",
                    opacity: 0.6,
                    boxShadow: "0px 6px 14px #36408D08",
                    fontSize: "14px",
                    color: "#1D1D1D",
                    textAlign :'left',
                  }}
                    inputProps={{
                      style: {
                        fontFamily: "Mundial, sans-serif",
                        fontSize: "14px",
                      },
                    }}
                    SelectDisplayProps={{
                      style: {
                        fontFamily: "Mundial, sans-serif",
                        fontSize: "14px",
                      },
                    }}

                >
                  {propertyTypeOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={filterPropertyType.indexOf(option) > -1} />
                      <Typography variant="caption" sx={{ fontWeight: 500 }}>
                        {option}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Amenities */}
            <Grid item xs={6} md={2}>
              <FormControl variant="outlined" size="small" fullWidth>
                <Select
                  multiple
                  value={filterAmenities}
                  onChange={handleAmenitiesChange}
                  IconComponent={ArrowDropDown}
                  displayEmpty
                  renderValue={(selected) =>
                    selected.length > 0 ? (
                      <Box display="flex" alignItems="center">
                        Amenities
                        <Box
                          component="span"
                          borderRadius="50%"
                          color="#FFFFFF"
                          fontSize="10px"
                          marginLeft="5px"
                          width="15px"
                          height="15px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          style={{
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          {selected.length}
                        </Box>
                      </Box>
                    ) : (
                      "Amenities"
                    )
                  }
                  style={{
                    width: "100%",
                    height: "40px",
                    border: "1px solid #1212121A",
                    borderRadius: "10px",
                    opacity: 0.6,
                    boxShadow: "0px 6px 14px #36408D08",
                    fontSize: "14px",
                    color: "#1D1D1D",
                    textAlign :'left',
                  }}
                    inputProps={{
                      style: {
                        fontFamily: "Mundial, sans-serif",
                        fontSize: "14px",
                      },
                    }}
                    SelectDisplayProps={{
                      style: {
                        fontFamily: "Mundial, sans-serif",
                        fontSize: "14px",
                      },
                    }}
                >
                  {amenitiesOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={filterAmenities.indexOf(option) > -1} />
                      <Typography variant="caption" sx={{ fontWeight: 500 }}>
                        {option}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Price Range */}
            <Grid item xs={6} md={2}>
              <Box
                onClick={handleClick}
                sx={{
                  width: "100%",
                  height: "40px",
                  border: "1px solid #1212121A",
                  borderRadius: "10px",
                  boxShadow: "0px 6px 14px #36408D08",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Typography variant="caption" 
                style={{
                  fontSize: "14px",
                  opacity: 0.75,
                  color: "#1D1D1D",
                  textAlign :'left',
                }}>
                  Price Range
                </Typography>
              </Box>

              {/* Popover for Price Range */}
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <Box
                    sx={{
                      width: 300,
                      padding: 2,
                      marginTop: 1.4,
                      boxShadow: "0px 6px 14px #36408D08",
                    }}
                  >
                    <Slider
                      value={priceRange}
                      onChange={handlePriceRangeChange}
                      valueLabelDisplay="auto"
                      min={minPrice}
                      max={maxPrice}
                      sx={{ color: theme.palette.primary.main, marginTop: 2 }}
                    />
                  </Box>
                </ClickAwayListener>
              </Popover>
            </Grid>

            {/* BHK */}
            <Grid item xs={4} md={1.4}>
              <FormControl variant="outlined" size="small" fullWidth>
                <Select
                  multiple
                  value={filterBhk}
                  onChange={handleBhkChange}
                  IconComponent={ArrowDropDown}
                  displayEmpty
                  renderValue={(selected) =>
                    selected.length > 0 ? (
                      <Box display="flex" alignItems="center">
                        BHK
                        <Box
                          component="span"
                          borderRadius="50%"
                          color="#FFFFFF"
                          fontSize="10px"
                          marginLeft="5px"
                          width="15px"
                          height="15px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          style={{
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          {selected.length}
                        </Box>
                      </Box>
                    ) : (
                      "BHK"
                    )
                  }
                  style={{
                    width: "100%",
                    height: "40px",
                    border: "1px solid #1212121A",
                    borderRadius: "10px",
                    opacity: 0.6,
                    boxShadow: "0px 6px 14px #36408D08",
                    fontSize: "14px",
                    color: "#1D1D1D",
                    textAlign :'left',
                  }}
                    inputProps={{
                      style: {
                        fontFamily: "Mundial, sans-serif",
                        fontSize: "14px",
                      },
                    }}
                    SelectDisplayProps={{
                      style: {
                        fontFamily: "Mundial, sans-serif",
                        fontSize: "14px",
                      },
                    }}
                >
                  {bhkOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={filterBhk.indexOf(option) > -1} />
                      <Typography variant="caption" sx={{ fontWeight: 500 }}>
                        {option}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            

            {/* Clear Filters */}
            {/* <Grid item xs={4} sm={1.6}>
              {(filterPropertyType.length > 0 ||
                filterAmenities.length > 0 ||
                filterPriceRange.length > 0 ||
                filterBhk.length > 0) && (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={clearFilters}
                  sx={{
                    height: "38px",
                    borderRadius: "8px",
                    marginTop: { xs: "10px", sm: 0 },
                    // in single line text 
                    whiteSpace: "nowrap",
                  }}
                >
                  Clear Filters
                </Button>
              )}
            </Grid> */}
          </Grid>
        </Card>
      </Grid>

        {/* Data Display */}
        <Grid item xs={12}>
          <Card
            sx={{
              backgroundColor: "#FFFFFF",
              border: "1px solid #1212121A",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            {viewMode === "card" ? (
              <ProjectsCard
                paginatedData={paginatedData}
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
                handleChangeItemsPerPage={handleChangeItemsPerPage}
              />
            ) : (
              <ProjectsGrid
                paginatedData={paginatedData}
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
                handleChangeItemsPerPage={handleChangeItemsPerPage}
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Projects;






















