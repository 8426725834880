import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { useNavigate, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { Container, Grid, Typography, Box, Paper, IconButton, Stack, Button, InputAdornment, CircularProgress } from '@mui/material';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import TopHeading from '../../../components/Top/TopHeading';
import { FeedImages, person, usericon } from '../../../assets'; 
import { projectsData } from '../projectsData';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { ArrowForward, Comment, Email, LocationOn, Person, Star } from '@mui/icons-material';
import CustomInput from '../../../components/Inputs/CustomInput';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { format, differenceInDays } from 'date-fns';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import { useMutation, useQuery } from 'react-query';
import { emailForm, getProjectData, getProjects } from '../../../api/services';
import { toast } from 'react-toastify';



interface ProjectData {
  _id: number;
  builderImage: string;
  builderName: string;
  builderOccupation: string;
  projectName: string;
  projectLocation: string;
  projectBHK: number[];
  unitsApartments: string;
  size: string;
  approvals: string;
  category: string;
  amenities: string[];
  locationHighlights: string;
  floorPlans: string | null;
  projectElevations: string | null;
  propertyType: string;
  priceRange: string;
  bhk: number;
  bath: number;
  createdAt: string;
  updatedAt: string;
  price: number;
  squareFeet: number;
  projectImage: string[];
}


const amenities = [
  "Swimming Pool",
  "24/7 Security",
  "Power Backup",
  "Club House",
  "Intercom",
  "Fire Alarm",
  "Car Parking",
  "Children Play Area",
  "Gym",
  "Sauna",
  "Spa",
  "BBQ Area",
  "Library",
  "Pet Friendly",
  "Business Center",
  "Guest Room",
];



const categories = ['Apartments', 'Villa', 'Land', 'Commercial', 'Office', 'Luxury Villas'];


const ProjectDetails: React.FC = () => {
  const [rating, setRating] = useState<number | string>('');
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    mobileNumber: ''
  });
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const projectId = id ?? '';  // Use a fallback or handle it explicitly

  // Use query to get the project data with the id as the parameter
  const {
    data: projectsData,
    isLoading: isProjectLoading,
    error: projectError,
  } = useQuery(["project", projectId], () => getProjectData(projectId), {
    enabled: !!projectId,  // Only fetch when there is a valid project ID
  });


  const { data: tempData, isLoading } = useQuery("getProjectData", getProjects);
  const AllProjects = tempData?.data;





  const projectData = projectsData?.data;

  // Assuming projectsData is available in the component scope.
  // const projectData = projectsData.data.data.find((project) => project.id === Number(id));

  const formatNumberIndian = (num: number) => {
    return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(num);
  };

  const [isOpen, setOpen] = useState(false);

  // Mutate function to handle the form submission
  const mutation = useMutation((data: any) => emailForm(data), {
    onSuccess: () => {
      toast.success('Form submitted successfully!');
      setFormValues({ name: '', email: '', mobileNumber: '' });
    },
    onError: (error: any) => {
      toast.error('Error submitting the form. Please try again.');
    },
    onSettled: () => {
      setLoading(false);
    }
  });

  if (!projectData) {
    return <Typography variant="h6">Project not found</Typography>;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);
    mutation.mutate(formValues); // Call the mutation with form values
  };

  const handleCancel = () => {
    setFormValues({ name: '', email: '', mobileNumber: '' });
  };

  // Function to handle rating changes and validate input
  const handleRatingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);

    // Ensure the value is between 1 and 5 and allow decimals
    if (value >= 1 && value <= 5) {
      setRating(value);
    } else if (event.target.value === '') {
      setRating('');
    }
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const today = new Date();
    const daysAgo = differenceInDays(today, date);
  
    if (daysAgo <= 10) {
      if (daysAgo === 0) return 'Today';
      if (daysAgo === 1) return '1 day ago';
      return `${daysAgo} days ago`;
    } else {
      // Format date as "Month Day, Year"
      return format(date, 'MMMM d, yyyy');
    }
  };

  const SlickArrowLeft: React.FC<any> = (props) => (
    <IconButton
      {...props}
      sx={{
        backgroundColor: '#ffffff',
        border: '2px solid #ffffff',
        color: '#da2e31',
        width: '50px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
        position: 'absolute',
        top: '50%',
        zIndex: 1,
        transform: 'translateY(-50%)',
        padding: 0,
        left: 0,
        '&:hover': {
          backgroundColor: '#da2e31',
          color: '#ffffff',
          border: '2px solid #da2e31',
        },
      }}
    >
      <FaArrowLeft />
    </IconButton>
  );
  
  const SlickArrowRight: React.FC<any> = (props) => (
    <IconButton
      {...props}
      sx={{
        backgroundColor: '#ffffff',
        border: '2px solid #ffffff',
        color: '#da2e31',
        width: '50px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
        position: 'absolute',
        top: '50%',
        zIndex: 1,
        transform: 'translateY(-50%)',
        padding: 0,
        right: 0,
        '&:hover': {
          backgroundColor: '#da2e31',
          color: '#ffffff',
          border: '2px solid #da2e31',
        },
      }}
    >
      <FaArrowRight />
    </IconButton>
  );



  


  const ProjectDetailsCarouselSettings = {
    arrows: true,
    centerMode: true,
    infinite: true,
    centerPadding: '500px',
    slidesToShow: 1,
    dots: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 1700,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      { breakpoint: 1600, settings: { centerPadding: '320px' } },
      { breakpoint: 992, settings: { centerPadding: '250px' } },
      { breakpoint: 768, settings: { centerPadding: '180px' } },
      { breakpoint: 767, settings: { centerPadding: '50px', dots: true } },
    ],
  };


// This can be used for form validation


if (isProjectLoading) {
  return <div>Loading project data...</div>;
}

if (projectError) {
  return <div>Error loading project data</div>;
}



  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <TopHeading 
        value="Project Details" 
        breadcrumbs={[
          { label: 'Projects', path: '/projects' },
        ]}
      />      
    <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="X7R-q9rsrtU"
        onClose={() => setOpen(false)}
      />

        <Box mb={9}>
        <Slider {...ProjectDetailsCarouselSettings}>
          {projectData.projectImages.map((proimg: string, index: number) => (
            <Box 
              key={index}
              sx={{ 
                height: projectData.projectImages.length > 1 ? '400px' : 'auto',
                width: '100%',
                position: 'relative',
                overflow: 'hidden',
                '&:hover img': {
                  transform: 'scale(1.15)',
                }
              }}
            >
              <img
                src={`https://api.realtyyards.com/uploads/${proimg}`}
                alt="projectImage"
                style={{
                  width: '100%', 
                  height: projectData.projectImages.length > 1 ? '100%' : 'auto',  
                  objectFit: 'cover', 
                  transition: 'transform 0.3s ease-in-out',
                  cursor: 'pointer',
                }}
              />
            </Box>
          ))}
        </Slider>
      </Box>


      <Box pb={10}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={8}>

    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        flexWrap: 'wrap',
      }}
    >
      {/* Left Side */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          gap: 2,
          width: '100%',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          mb:4,
        }}
      >
        {/* Category */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#da2e31',
            borderRadius: 0,
            px: 2,
            py: 0.5,
            color: '#fff',
          }}
        >
          <Typography variant="body2" sx={{ color: '#fff' , fontWeight: 600 }}>
            {projectData.propertyType}
          </Typography>
        </Box>

        {/* Calendar */}
        <Box sx={{ display: 'flex', alignItems: 'center' , ml: { xs: 0, sm: 2 } }}>
          <Typography variant="body2" sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
            <CalendarMonthIcon fontSize="small"  color='primary'/>
            <Box component="span" sx={{ ml: 0.5 }}>
              {projectData.updatedAt}
            </Box>
          </Typography>
        </Box>

        {/* Liked by Persons */}
        <Box sx={{ display: 'flex', alignItems: 'center' , ml: { xs: 0, sm: 2 } }}>
          <Typography variant="body2" sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
            <FavoriteBorderIcon fontSize="small" color='primary' />
            <Box component="span" sx={{ ml: 0.5 }}>
              12
            </Box>
          </Typography>
        </Box>
      </Box>

      {/* Right Side */}
      {/* <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '50%',
        }}
      >

      </Box> */}
    </Box>




        <Box display="flex" gap={1} mb={1}>
          <Typography variant="h3" sx={{ fontWeight : 700, textAlign :'left' }}>
          {projectData.projectName}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" gap={1} mb={2}>
          <LocationOn fontSize="small" color="primary" />
          <Typography variant="body2" color="text.secondary"sx={{ fontWeight: 450 }}>
            {projectData.projectLocation}
          </Typography>
        </Box>


          <Box display="flex" alignItems="center" gap={0.6} mb={1.5}>
            <Box
              sx={{
                background: '#da2e31',
                borderRadius: '15px',
                width: '4px',
                height: '25px',
                marginRight: 1,
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>Description</Typography>
          </Box>
                                
              {/* Description goes here */}
            <Typography variant="caption" mb={4} paragraph sx={{ color: 'text.secondary', textAlign:'justify' }}>
              {projectData.projectdescription}
            </Typography>

          <Box display="flex" alignItems="center" gap={1} mb={1}>
                <Box
                  sx={{
                    background: '#da2e31',
                    borderRadius: '15px',
                    width: '4px',
                    height: '25px',
                    marginRight: 1,
                  }}
                />
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>Property Detail</Typography>
            </Box>
                          
            <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection : { xs: 'column', md: 'row' },
                      gap: 2,
                      backgroundColor: '#e9f3f6',
                      borderRadius: '2px',
                      mb: 4,
                      width: '100%',
                    }}
                  >
                    {/* Left Side */}
                    <Box
                      sx={{
                        width: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        padding:4,
                      }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ width: '50%' }}>
                          <Typography variant="body2" sx={{ textAlign: 'left' }}>
                            Property ID
                          </Typography>
                        </Box>
                        <Box sx={{ width: '50%' }}>
                          <Typography variant="body2" sx={{fontWeight: '600', textAlign: 'left' }}>
                            {projectData._id} 
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ width: '50%' }}>
                          <Typography variant="body2" sx={{ textAlign: 'left' }}>
                            Home Area
                          </Typography>
                        </Box>
                        <Box sx={{ width: '50%' }}>
                          <Typography  variant="body2" sx={{fontWeight: '600', textAlign: 'left' }}>
                            {projectData.homearea} sqft
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ width: '50%' }}>
                          <Typography variant="body2" sx={{ textAlign: 'left' }}>
                            BHK
                          </Typography>
                        </Box>
                        <Box sx={{ width: '50%' }}>
                        <Typography variant="body2" sx={{ fontWeight: '600', textAlign: 'left' }}>
                            {
                              projectData.projectBHK.map((bhk: number, index: number) => (
                                <span key={index}>
                                  {bhk}
                                  {index < projectData.projectBHK.length - 1 && ', '}
                                </span>
                              ))
                            }
                          </Typography>

                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ width: '50%' }}>
                          <Typography variant="body2" sx={{ textAlign: 'left' }}>
                            Baths
                          </Typography>
                        </Box>
                        <Box sx={{ width: '50%' }}>
                          <Typography  variant="body2" sx={{fontWeight: '600', textAlign: 'left' }}>
                            {projectData.baths}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ width: '50%' }}>
                          <Typography variant="body2" sx={{ textAlign: 'left' }}>
                            Year built
                          </Typography>
                        </Box>
                        <Box sx={{ width: '50%' }}>
                        <Typography variant="body2" sx={{ fontWeight: '600', textAlign: 'left' }}>
                          {projectData.yearbuilt}
                        </Typography>

                        </Box>
                      </Box>
                    </Box>

                    {/* Right Side */}
                    <Box
                      sx={{
                        width: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        padding:4,
                      }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ width: '50%' }}>
                          <Typography variant="body2" sx={{ textAlign: 'left' }}>
                          units Apartments
                          </Typography>
                        </Box>
                        <Box sx={{ width: '50%' }}>
                          <Typography  variant="body2" sx={{fontWeight: '600', textAlign: 'left' }}>
                            {projectData.unitsApartments} sqft
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ width: '50%' }}>
                          <Typography variant="body2" sx={{ textAlign: 'left' }}>
                            Dimensions
                          </Typography>
                        </Box>
                        <Box sx={{ width: '50%' }}>
                          <Typography  variant="body2" sx={{fontWeight: '600', textAlign: 'left' }}>
                          {projectData.dimensions} 
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ width: '50%' }}>
                          <Typography variant="body2" sx={{ textAlign: 'left' }}>
                            Beds
                          </Typography>
                        </Box>
                        <Box sx={{ width: '50%' }}>
                        <Typography variant="body2" sx={{ fontWeight: '600', textAlign: 'left' }}>
                            {
                              projectData.projectBHK.map((bhk: number, index: number) => (
                                <span key={index}>
                                  {bhk}
                                  {index < projectData.projectBHK.length - 1 && ', '}
                                </span>
                              ))
                            }
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ width: '50%' }}>
                          <Typography variant="body2" sx={{ textAlign: 'left' }}>
                            Price
                          </Typography>
                        </Box>
                        <Box sx={{ width: '50%' }}>
                          <Typography  variant="body2" sx={{fontWeight: '600', textAlign: 'left' }}>
                          <b> ₹ {formatNumberIndian(projectData.price)}</b> INR
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ width: '50%' }}>
                          <Typography variant="body2" sx={{ textAlign: 'left' }}>
                            Property Status
                          </Typography>
                        </Box>
                        <Box sx={{ width: '50%' }}>
                          <Typography  variant="body2" sx={{fontWeight: '600', textAlign: 'left' }}>
                            {projectData.status == "Available" ? "For Sale (Available)" : "Sold"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>


{/* 
                <Box display="flex" alignItems="center" gap={1} mb={1}>
                    <Box
                      sx={{
                        background: '#da2e31',
                        borderRadius: '15px',
                        width: '4px',
                        height: '25px',
                        marginRight: 1,
                      }}
                    />
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>Facts and Features</Typography>
                </Box> */}

                    {/* <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: 2,
                          backgroundColor: '#e9f3f6',
                          padding: 2,
                          borderRadius: '2px',
                          mb: 4,
                          width: '100%',
                        }}
                      >
                        <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <img src={FeedImages.feed1} alt="Living Room" style={{ width: '30px', height: '30px', marginRight: '8px' }} />
                            <Box>
                              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Living Room</Typography>
                              <Typography variant="body2">20 x 16 sq feet</Typography>
                            </Box>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <img src={FeedImages.feed1} alt="Garage" style={{ width: '30px', height: '30px', marginRight: '8px' }} />
                            <Box>
                              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Garage</Typography>
                              <Typography variant="body2">20 x 16 sq feet</Typography>
                            </Box>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <img src={FeedImages.feed1} alt="Dining Area" style={{ width: '30px', height: '30px', marginRight: '8px' }} />
                            <Box>
                              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Dining Area</Typography>
                              <Typography variant="body2">20 x 16 sq feet</Typography>
                            </Box>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <img src={FeedImages.feed1} alt="Bedroom" style={{ width: '30px', height: '30px', marginRight: '8px' }} />
                            <Box>
                              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Bedroom</Typography>
                              <Typography variant="body2">20 x 16 sq feet</Typography>
                            </Box>
                          </Box>
                        </Box>

                        <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <img src={FeedImages.feed1} alt="Bathroom" style={{ width: '30px', height: '30px', marginRight: '8px' }} />
                            <Box>
                              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Bathroom</Typography>
                              <Typography variant="body2">20 x 16 sq feet</Typography>
                            </Box>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <img src={FeedImages.feed1} alt="Gym Area" style={{ width: '30px', height: '30px', marginRight: '8px' }} />
                            <Box>
                              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Gym Area</Typography>
                              <Typography variant="body2">20 x 16 sq feet</Typography>
                            </Box>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <img src={FeedImages.feed1} alt="Garden" style={{ width: '30px', height: '30px', marginRight: '8px' }} />
                            <Box>
                              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Garden</Typography>
                              <Typography variant="body2">20 x 16 sq feet</Typography>
                            </Box>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <img src={FeedImages.feed1} alt="Parking" style={{ width: '30px', height: '30px', marginRight: '8px' }} />
                            <Box>
                              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Parking</Typography>
                              <Typography variant="body2">20 x 16 sq feet</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box> */}



                
                {/* Gallery */}
                <Box display="flex" alignItems="center" gap={1} mb={1}>
                  <Box
                    sx={{
                      background: '#da2e31',
                      borderRadius: '15px',
                      width: '4px',
                      height: '25px',
                      // marginRight: 1,
                    }}
                  />
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Gallery</Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: 2,
                    width: '100%',
                    backgroundColor: 'transparent',
                    borderRadius: '5px',
                    mb: 4,
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: '100%', md: '50%' },
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        paddingTop: '56.25%', // 16:9 Aspect Ratio
                        overflow: 'hidden',
                        borderRadius: '5px',
                        backgroundColor: '#fff',
                      }}
                    >
                      <img
                        src={`https://api.realtyyards.com/uploads/${projectData.projectImages[0]}`}
                        alt="Image 1"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        position: 'relative',
                        paddingTop: '56.25%', 
                        overflow: 'hidden',
                        borderRadius: '5px',
                        backgroundColor: '#fff',
                      }}
                    >
                      <img
                        src={`https://api.realtyyards.com/uploads/${projectData.projectImages[0]}`}
                        alt="Image 2"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        position: 'relative',
                        paddingTop: '56.25%', // 16:9 Aspect Ratio
                        overflow: 'hidden',
                        borderRadius: '5px',
                        backgroundColor: '#fff',
                      }}
                    >
                      <img
                        src={`https://api.realtyyards.com/uploads/${projectData.projectImages[0]}`}
                        alt="Image 2"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                  </Box>

                  {/* if mobileNumber then hide the below images */}
                  { 

                  <Box
                    sx={{
                      width: { xs: '100%', md: '50%' },
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        paddingTop: '177.78%', 
                        overflow: 'hidden',
                        borderRadius: '5px',
                        backgroundColor: '#fff',
                      }}
                    >
                      <img
                        src={`https://api.realtyyards.com/uploads/${projectData.projectImages[0]}`}
                        alt="Image 3"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                  </Box>
                  }
                </Box>



















          {/* aminities */}
          <Box display="flex" alignItems="center" gap={1} mb={1}>
        <Box
          sx={{
            background: '#da2e31',
            borderRadius: '15px',
            width: '4px',
            height: '25px',
            marginRight: 1,
          }}
        />
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Amenities</Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          width: '100%',
          backgroundColor: '#e9f3f6',
          borderRadius: '5px',
          mb: 4,
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            padding: 4,
          }}
        >
         {projectData.amenities.slice(0, 8).map((item: string, index: number) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box
              sx={{
                backgroundColor: "primary.main",
                borderRadius: '50%',
                width: '8px',
                height: '8px',
              }}
            />
            <Typography>
              {item.length > 20 ? `${item.slice(0, 25)}...` : item}
            </Typography>
          </Box>
        ))}

        </Box>

        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            padding: 4,
          }}
        >
          {projectData.amenities.slice(0, 8).map((item: string, index: number) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box
                sx={{
                  backgroundColor: "primary.main",
                  borderRadius: '50%',
                  width: '8px',
                  height: '8px',
                }}
              />
              <Typography>
                {item.length > 20 ? `${item.slice(0, 25)}...` : item}
              </Typography>
            </Box>
          ))}

        </Box>
      </Box>














      <Box display="flex" alignItems="center" gap={1} mb={1}>
        <Box
          sx={{
            background: '#da2e31',
            borderRadius: '15px',
            width: '4px',
            height: '25px',
            marginRight: 1,
          }}
        />
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Location Highlights</Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          width: '100%',
          backgroundColor: '#e9f3f6',
          borderRadius: '5px',
          mb: 4,
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '100%' },
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            padding: 4,
          }}
        >
          {projectData.locationhighlight?.slice(0, 8).map((item: string, index: number) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box
                sx={{
                  backgroundColor: "primary.main",
                  borderRadius: '50%',
                  width: '8px',
                  height: '8px',
                }}
              />
              <Typography>
                {item}
                </Typography>
            </Box>
          ))}
        </Box>

        {/* <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            padding: 4,
          }}
        >
          {projectData.locationhighlight?.slice(8,16).map((item, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box
                sx={{
                  backgroundColor: "primary.main",
                  borderRadius: '50%',
                  width: '8px',
                  height: '8px',
                }}
              />
              <Typography>
                {item}
              </Typography>
            </Box>
          ))}
        </Box> */}
      </Box>









                {/* Location here with iframe okay */}
                <Box display="flex" alignItems="center" gap={1} mb={1}>
                    <Box
                      sx={{
                        background: '#da2e31',
                        borderRadius: '15px',
                        width: '4px',
                        height: '25px',
                        marginRight: 1,
                      }}
                    />
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>Location</Typography>
              </Box>
                    <Box mb={6} sx={{ height: 300 }}>

                <iframe
                  src={projectData.locationiframe}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>

                </Box>

            



              {/* Reviews goes here */}
      <Box>
      <Box display="flex" alignItems="center" gap={1} mb={1}>
        <Box
          sx={{
            background: '#da2e31',  
            borderRadius: '15px',
            width: '4px',
            height: '25px',
            marginRight: 1,
          }}
        />
        
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Review
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          width: '100%',
          backgroundColor: 'transparent',
          padding: '10px',
          borderRadius: '5px',
          marginBottom: '10px',
        }}
      >
        <form
          action=""
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <CustomInput
            placeholder="Type your comment"
            multiline
            rows={4}
            required
          />

          <CustomInput
            placeholder="Name"
            endAdornment={
              <InputAdornment position="end">
                <Person color="primary" />
              </InputAdornment>
            }
            required
          />

          <CustomInput
            placeholder="Email"
            type="email"
            endAdornment={
              <InputAdornment position="end">
                <Email  color="primary"/>
              </InputAdornment>
            }
            required
          />

            <CustomInput
            placeholder="Rating"
            type="number"
            inputProps={{
              min: 1,
              max: 5,
              step: '0.1',
            }}
            value={rating}
            onChange={handleRatingChange}
            endAdornment={
              <InputAdornment position="end">
                <Star color='primary' />
              </InputAdornment>
            }
            required
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: '#da2e31',
              width: 'fit-content',
              color: '#fff',
              padding: '10px 20px',
              borderRadius: '5px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#a11c1b',
              },
            }}
          >
            Submit
          </Button>
        </form>
      </Box>
    </Box>



            </Grid>
























            <Grid item xs={12} lg={4}>

            <Box
            sx={{
                padding: 3,
                backgroundColor: '#fff',
                borderRadius: 2,
                ml: 2,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                maxWidth: 400,
            }}
        >
            <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                    fontWeight: '600',
                    mb: 2,
                    textAlign: 'left',
                    color: '#333',
                }}
            >
                Fill in Your Details
            </Typography>
            <Box>
                {/* Form Fields */}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomInput
                            name="name"
                            placeholder="Your Name"
                            value={formValues.name}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomInput
                            name="email"
                            type="email"
                            placeholder="Your Email"
                            value={formValues.email}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomInput
                            name="mobileNumber"
                            type="tel"
                            placeholder="Your mobileNumber Number"
                            value={formValues.mobileNumber}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                </Grid>

                {/* Buttons */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 3,
                    }}
                >
                    <Button
                        onClick={handleCancel}
                        variant="outlined"
                        color="secondary"
                        sx={{
                            marginRight: 'auto',
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        variant="contained"
                        disabled={loading} // Disable button while loading
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Submit'}
                    </Button>
                </Box>
            </Box>
        </Box>


                {/* Top Categories goes here*/}
                <Box
                  sx={{
                    padding: 3,
                    backgroundColor: '#fff',
                    borderRadius: 2,
                    mt: 4,
                    ml: 2,
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  }}
                >

                   <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        fontWeight: '600',
                        mb: 2,
                        textAlign: 'left',
                        color: '#333',
                      }}
                    >
                    Top Categories
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 2,
                      justifyContent: 'center',
                    }}
                  >
                    {categories.map((category, index) => (
                      <Box
                        key={index}
                        sx={{
                          backgroundColor: '#ff5f6d',
                          color: '#fff',
                          padding: '10px 15px',
                          borderRadius: '25px',
                          cursor: 'pointer',
                          transition: 'background-color 0.3s, transform 0.2s',
                          '&:hover': {
                            backgroundColor: '#a11c1b',
                            transform: 'scale(1.05)',
                          },
                          textAlign: 'center',
                          display: 'inline-block',
                        }}
                      >
                        {category}
                      </Box>
                    ))}
                  </Box>
                </Box>











    <Box
      sx={{
        padding: 3,
        backgroundColor: '#fff',
        borderRadius: 2,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        mt: 4,
        ml: 2,
      }}
    >
                   <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        fontWeight: '600',
                        mb: 2,
                        textAlign: 'left',
                        color: '#333',
                      }}
                    >
                      Recent Projects
                    </Typography>
      {AllProjects.slice(0,5).map((project: any, index: number) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            mb: 3,
            borderRadius: '8px',
            padding: 2,
            border: '1.5px solid #f1f1f1',
            overflow: 'hidden',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'translateY(-5px)',
              boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <img
            src={`https://api.realtyyards.com/uploads/${project.projectImages[0]}`}
            alt={project.projectName}
            style={{
              width: 80,
              height: 80,
              objectFit: 'cover',
              borderRadius: '8px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: '600',
                mb: 1,
                color: '#333',
                textTransform: 'capitalize',
                textAlign: 'left',
              }}
            >
              {project.projectName}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontWeight: '500',
                color: '#666',
                textAlign: 'left',
              }}
            >
              <LocationOn fontSize="small" color="primary" />
              {project.projectLocation}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
          

<Box sx={{ mt: 4 }} />
          

    <Box
      sx={{
        padding: 3,
        backgroundColor: '#fff',
        borderRadius: 2,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        mt: 4,
        maxWidth: '600px',
        margin: '0 auto',
        ml: 2,
      }}
    >
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{
          fontWeight: '600',
          mb: 2,
          textAlign: 'left',
          color: '#333',
        }}
      >
        Reviews
      </Typography>

     
      
      {
        projectData?.reviews ? 
        projectData.reviews.map((review: any, index: number) => (
          <Box
          key={index}
          sx={{
            padding: 2,
            mb: 3,
            borderRadius: '8px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'translateY(-3px)',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              mb: 1,
            }}
          >
            <img
              src={usericon}
              alt="User"
              style={{
                width: 50, 
                height: 50,
                // borderRadius: '50%',
                objectFit: 'cover',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            />
            <Box>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: '600',
                  color: '#333',
                  textAlign: 'left',
                }}
              >
                {/* John Doe  */}
                {review.name}
                {/* {product.reviews[i].name} */}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: '#666',
                  textAlign: 'left',
                }}
                paragraph
              >
                {formatDate(review.updatedAt)}
              </Typography>
            </Box>
          </Box>

          <Typography
            variant="caption"
            sx={{
              color: '#222',
              mb: 2,
              textAlign: 'left',
            }}
            paragraph
          >
            {/* Great project with all the amenities. I would recommend this project to everyone. */}
            {review.message}
            {/* {product.reviews[i].comment} */}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 0.5,
            }}
          >
            {review.rating}
            {[...Array(5)].map((_, i) => {
              const roundedRating = Math.round(review.rating * 2) / 2; // Round to nearest 0.5
              if (i + 1 <= roundedRating) {
                // Full star
                return <FaStar key={i} style={{ color: '#da2e31', transition: 'color 0.3s' }} />;
              } else if (i + 0.5 === roundedRating) {
                // Half star
                return <FaStarHalfAlt key={i} style={{ color: '#da2e31', transition: 'color 0.3s' }} />;
              } else {
                // Empty star
                return <FaRegStar key={i} style={{ color: '#ddd', transition: 'color 0.3s' }} />;
              }
            })}
          </Box>
        </Box>
        )) : 

      ([...Array(3)].map((_, i) => (
        <Box
          key={i}
          sx={{
            padding: 2,
            mb: 3,
            borderRadius: '8px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'translateY(-3px)',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              mb: 1,
            }}
          >
            <img
              src={person}
              alt="User"
              style={{
                width: 50,
                height: 50,
                borderRadius: '50%',
                objectFit: 'cover',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            />
            <Box>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: '600',
                  color: '#333',
                  textAlign: 'left',
                }}
              >
                John Doe 
                {/* {product.reviews[i].name} */}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: '#666',
                  textAlign: 'left',
                }}
              >
                2 days ago
                {/* {product.reviews[i].date} */}
              </Typography>
            </Box>
          </Box>

          <Typography
            variant="caption"
            sx={{
              color: '#222',
              mb: 2,
              textAlign: 'left',
            }}
            paragraph
          >
            Great project with all the amenities. I would recommend this project to everyone.
            {/* {product.reviews[i].comment} */}
          </Typography>

          <Box  
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 0.5,
            }}
          >
            {[...Array(5)].map((_, i) => (
              <FaStar
                key={i}
                style={{
                  color: i < 4 ? '#da2e31' : '#ddd',
                  transition: 'color 0.3s',
                }}
              />
            ))}
          </Box>
        </Box>
      )))

      }
    </Box>


            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default ProjectDetails;