import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Carousel from 'react-material-ui-carousel';
import { Card, CardMedia, CardContent, CardActions, IconButton } from '@mui/material';
import { Info, Share } from '@mui/icons-material';

// Styled components for the carousel
const CarouselItem = styled(Card)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  height: 400,
  overflow: 'hidden',
  boxShadow: theme.shadows[10],
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[20],
  },
}));

const CarouselMedia = styled(CardMedia)(({ theme }) => ({
  height: '100%',
  transition: 'transform 0.5s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const CarouselContent = styled(CardContent)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.6)',
  color: theme.palette.common.white,
  padding: theme.spacing(2),
  width: '100%',
  transition: 'opacity 0.5s ease',
  opacity: 0,
  '&:hover': {
    opacity: 1,
  },
}));

const CarouselActions = styled(CardActions)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  display: 'flex',
  gap: theme.spacing(1),
}));

const GalleryCarousel = ({ items }: any) => (
  <Box sx={{ padding: 4 }}>
    <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: '700', mb: 2}}>
      Featured Highlights
    </Typography>
    <Carousel
      animation="fade"
      indicators={false} // Disable default indicators for a cleaner look
      navButtonsAlwaysVisible
      cycleNavigation
    >
      {items.map((item: any, index: any) => (
        <CarouselItem key={index}>
          <CarouselMedia
            // component="img"
            image={item.img}
            // alt={item.title}
          />
          <CarouselContent>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: '700', color: "#ccc" }}>
              {item.title}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: '700', color: "#ccc" }}>{item.description}</Typography>
          </CarouselContent>
          <CarouselActions>
            <IconButton color="inherit">
              <Info />
            </IconButton>
            <IconButton color="inherit">
              <Share />
            </IconButton>
          </CarouselActions>
        </CarouselItem>
      ))}
    </Carousel>
  </Box>
);

export default GalleryCarousel;
