import React from 'react';
import { Box, Grid, Card, CardContent, Typography, Avatar, useTheme, Container } from '@mui/material';

interface CardData {
  icon: string;
  number: string;
  title: string;
  description: string;
}

interface AminitiesProps {
  cards: CardData[];
}

const Aminities: React.FC<AminitiesProps> = ({ cards }) => {
  const theme = useTheme();

  return (
    <Box sx={{ flexGrow: 1, px: { xs: 2, sm: 4, md: 6, lg: 8 }, py: 4 }}>
      <Container>
        <Typography variant="h6" color="primary" sx={{ textAlign: 'left', marginBottom: '8px' }}>
          Our Amenities
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'left', marginBottom: '24px' }}>
          Building Amenities
        </Typography>
        <Grid container spacing={3}>
          {cards.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card
                sx={{
                  textAlign: 'center',
                  padding: '20px',
                  boxShadow: '0px 5px 15px rgba(0,0,0,0.1)',
                  borderRadius: '10px',
                  transition: 'background-color 0.3s, color 0.3s, transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                    color: '#ffffff', 
                    transform: 'translateY(-10px)',
                    boxShadow: '0px 10px 20px rgba(0,0,0,0.2)',
                    '& .MuiTypography-root': {
                      color: '#ffffff', 
                    },
                    '& .MuiAvatar-root': {
                      backgroundColor: 'rgba(255, 255, 255, 0.75)', 
                    },
                  },
                }}
              >
                <Avatar
                  src={card.icon}
                  sx={{
                    width: 50,
                    height: 50,
                    backgroundColor: 'rgba(218, 46, 49, 0.2)',
                    margin: '0 auto',
                    marginBottom: '20px',
                    padding: 3,
                    transition: 'background-color 0.3s',
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: '#da2e31',
                    fontWeight: 'bold',
                    marginBottom: '8px',
                  }}
                >
                  {card.number}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    marginBottom: '8px',
                  }}
                >
                  {card.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'gray',
                  }}
                >
                  {card.description}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Aminities;
