import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography, IconButton, Box } from '@mui/material';
import { Facebook, Twitter, LinkedIn, YouTube, Email, LocationOn, Phone } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { logo2 } from '../../assets';

// Styled components
const FooterSection = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const FooterTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 600,
  marginBottom: theme.spacing(1.2),
}));

const FooterItem = styled(Typography)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.main,
    transition: 'all 0.3s ease',
    textDecoration: 'underline',
  },
}));

const FooterSection1 = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const FooterItem1 = styled('div')({
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const Footer = () => {
  const navigate = useNavigate();

  // Navigation handler
  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <Box sx={{ boxShadow: '0px -10px 15px rgba(0,0,0,0.2)', marginTop: '50px', backgroundColor: '#f9f9f9' }}>
      <Container>
        <footer>
          <Container sx={{ padding: { xs: '20px', sm: '40px' } }}>
            <Grid container spacing={3}>
              {/* About Section */}
              <Grid item xs={12} sm={6} md={5}>
                <FooterSection>
                  <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, flexDirection: 'column' }}>
                    <img src={logo2} alt="Logo" style={{ width: '160px' }} />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1, width: '90%' }}>
                    <Typography variant="caption" paragraph sx={{ textAlign: 'left', fontWeight: '500', marginRight: '10px' }}>
                      At Realty Yards, we’re not just building properties—we’re crafting lasting legacies.
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                    <IconButton color="primary" onClick={() => window.open('#', '_blank')} aria-label="Facebook">
                      <Facebook />
                    </IconButton>
                    <IconButton color="primary" onClick={() => window.open('#', '_blank')} aria-label="Twitter">
                      <Twitter />
                    </IconButton>
                    <IconButton color="primary" onClick={() => window.open('https://www.linkedin.com/company/realtyyards/', '_blank')} aria-label="LinkedIn">
                      <LinkedIn />
                    </IconButton>
                    <IconButton color="primary" onClick={() => window.open('https://www.youtube.com/@realtyyards', '_blank')} aria-label="YouTube">
                      <YouTube />
                    </IconButton>
                  </Box>
                </FooterSection>
              </Grid>

              {/* Company Section */}
              <Grid item xs={12} sm={6} md={3}>
                <FooterSection sx={{ marginBottom: 0, textAlign: 'left' }}>
                  <FooterTitle>Quick Links</FooterTitle>
                  <FooterItem onClick={() => handleNavigation('/home')}>
                    <Typography variant="caption" sx={{ fontWeight: '500' }}>Home</Typography>
                  </FooterItem>
                  <FooterItem onClick={() => handleNavigation('/about')}>
                    <Typography variant="caption" sx={{ fontWeight: '500' }}>About</Typography>
                  </FooterItem>
                  <FooterItem >
                    <Typography variant="caption" sx={{ fontWeight: '500' }}>Gallery</Typography>
                  </FooterItem>
                  <FooterItem onClick={() => handleNavigation('/projects')}>
                    <Typography variant="caption" sx={{ fontWeight: '500' }}>Projects</Typography>
                  </FooterItem>
                  <FooterItem onClick={() => handleNavigation('/contact')}>
                    <Typography variant="caption" sx={{ fontWeight: '500' }}>Contact us</Typography>
                  </FooterItem>
                  <FooterItem onClick={() => handleNavigation('/nri')}>
                    <Typography variant="caption" sx={{ fontWeight: '500' }}>NRI</Typography>
                  </FooterItem>
                </FooterSection>
              </Grid>

              {/* Services Section */}
              <Grid item xs={12} sm={6} md={3}>
                <FooterSection sx={{ marginBottom: 0, textAlign: 'left' }}>
                  <FooterTitle>Contact Us</FooterTitle>
                  <Typography variant="caption" paragraph sx={{ textAlign: 'justify', fontWeight: '500' }}>
                    <LocationOn sx={{ verticalAlign: 'middle', marginRight: '8px', color: "primary.main" }} /> 5 th Floor, Survey No.135, Brundavan colony, Plot No. 36, Gandipet Main Rd, Narsingi, Hyderabad, Narsingi, Telangana 500075.
                  </Typography>
                  <Typography variant="caption" paragraph sx={{ textAlign: 'justify', fontWeight: '500' }}>
                    <Phone sx={{ verticalAlign: 'middle', marginRight: '8px', color: "primary.main" }} />
                    <a href="tel:+919247347447" style={{ textDecoration: 'none' }}>
                      <Typography variant="caption" color="primary">
                        +91 9 247 347 447
                      </Typography>
                    </a>
                  </Typography>
                  <Typography variant="caption" paragraph sx={{ textAlign: 'justify', fontWeight: '500' }}>
                    <Email sx={{ verticalAlign: 'middle', marginRight: '8px', color: "primary.main" }} />
                    <Typography variant="caption" color="primary">
                      <a href="mailto:info@realtyyards.com" style={{ textDecoration: 'none', color: 'inherit' }}>
                        info@realtyyards.com
                      </a>
                    </Typography>
                  </Typography>
                </FooterSection>
              </Grid>

              {/* Footer Links Section */}
              {/* <Grid item xs={12} sm={6} md={4}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: { xs: 'column', md: 'row' }, gap: { xs: 1, md: 3 } }}>
                  <FooterSection1>
                    <FooterItem1 onClick={() => handleNavigation('/terms')}>
                      <Typography variant="caption" sx={{ fontWeight: 500, color: '#fff' }}>
                        Terms & Conditions
                      </Typography>
                    </FooterItem1>
                    <FooterItem1 onClick={() => handleNavigation('/claim')}>
                      <Typography variant="caption" sx={{ fontWeight: 500, color: '#fff' }}>
                        Claim
                      </Typography>
                    </FooterItem1>
                    <FooterItem1 onClick={() => handleNavigation('/privacy')}>
                      <Typography variant="caption" sx={{ fontWeight: 500, color: '#fff' }}>
                        Privacy & Policy
                      </Typography>
                    </FooterItem1>
                  </FooterSection1>
                </Box>
              </Grid> */}
            </Grid>
          </Container>

          {/* Copyright Area */}
          <Container sx={{ backgroundColor: '#da2e31', padding: { xs: '8px', sm: '10px' }, height: 'auto' }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={4} sx={{ textAlign: 'left' }}>
                <Typography variant="caption" sx={{ fontWeight: '500', color: '#fff' }}>
                  All Rights Reserved @ Company <span className="current-year"></span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ textAlign: 'left' }}>
                <Typography variant="caption" sx={{ fontWeight: '500', color: '#fff' }}>
                  Developed by <a href="https://dezignshark.com" style={{ color: '#fff', textDecoration: 'underline' }}><b>Dezign Shark</b></a>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    textAlign: 'left',
                    gap: { xs: 1, md: 3 },
                  }}
                >
                  <FooterSection1 sx={{flexDirection :{ xs: 'column', md: 'row' }}}>
                    <FooterItem1 onClick={() => handleNavigation('/terms')}>
                      <Typography variant="caption" sx={{ fontWeight: 500, color: '#fff' }}>
                        Terms & Conditions
                      </Typography>
                    </FooterItem1>
                    <FooterItem1 onClick={() => handleNavigation('/claim')}>
                      <Typography variant="caption" sx={{ fontWeight: 500, color: '#fff' }}>
                        Claim
                      </Typography>
                    </FooterItem1>
                    <FooterItem1 onClick={() => handleNavigation('/privacy')}>
                      <Typography variant="caption" sx={{ fontWeight: 500, color: '#fff' }}>
                        Privacy & Policy
                      </Typography>
                    </FooterItem1>
                  </FooterSection1>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </footer>
      </Container>
    </Box>
  );
};

export default Footer;
