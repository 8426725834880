import React, { useEffect, useState } from 'react';
import HeroSectionStyle from './HeroSectionStyle';
import { AminitiesImages, FeedImages, house1, house2, house3, logo1, temp } from '../../assets';
import AboutUsSectionOne from './AboutUsSection';
import AboutUsSectionTwo from './AboutUsSectionTwo';
import Feature from './Services';
import ApartmentPlan from './ApartmentPlan';
import Aminities from './Aminities';
import Testimonial from './Testimonial';
import LatestNews from './LatestNews';
import AboutUSInfo from './AboutUsInfo';


// Sample data for Hero Section
const heroData = [
  {
    subtitle: 'Welcome Home',
    Title: 'Find Your Dream Home',
    Desc: 'Discover the best homes available today, with beautiful views and comfortable living spaces.',
    buttonText: 'Explore Now',
    learnMoreButtonText: 'Learn More',
    videoButton: true,
    heroimage: FeedImages.feed5,
    variationLeft: false,
  },
  {
    subtitle: 'Luxury Living',
    Title: 'Live in Luxury',
    Desc: 'Experience the epitome of luxury living in our exclusive properties.',
    buttonText: 'Get Started',
    learnMoreButtonText: 'Learn More',
    videoButton: false,
    heroimage: FeedImages.feed3,
    variationLeft: true,
  },
  {
    subtitle: 'Modern Design',
    Title: 'Modern Living Spaces',
    Desc: 'Discover the best modern living spaces with the latest designs and amenities.',
    buttonText: 'Explore Now',
    learnMoreButtonText: 'Learn More',
    videoButton: false,
    heroimage: FeedImages.moon,
    variationLeft: false,
  }
];

// Sample data for Features Section
const featureData = [
  {
    title: 'Apartments Services',
    // shortDescription: 'Whether you\'re buying or selling, our expert advice ensures you get the best deal.',
    shortDescription: 'Find the perfect apartment that suits your lifestyle and budget, with our expert guidance every step of the way.',
    icon: 'build',
    img: house1,
    buttonText: 'Explore Homes',
    active: true,
  },
  {
    title: 'Villas Services',
    shortDescription: 'Discover the finest villas in the most desirable locations, with top-tier amenities and stunning views.',
    icon: 'home',
    img: house2,
    buttonText: 'Explore Homes',
    active: false,
  },
  {
    title: 'Plot Services',
    shortDescription: 'Invest in open plots and villa plots with excellent growth potential, in prime locations for maximum returns.',
    icon: 'people',
    img: house3,
    buttonText: 'Meet Our Team',
    active: false,
  },
  // {
  //   title: 'Legal Assistance',
  //   shortDescription: 'Navigate the legal complexities of real estate transactions with ease, backed by our experienced team.',
  //   icon: 'people',
  //   img: house3,
  //   buttonText: 'Meet Our Team',
  //   active: false,
  // }
];

// Sample data for Title Section (Features)
const titleSectionData = {
  sectionClasses: 'custom-section-class',
  headingClasses: 'custom-heading-class',
  title: 'Our Services',
  subtitle: 'Expert Guidance for Every Step',
};


const apartmentsPlanData = {
  "first": {
    "title": "Luxury Villas",
    "description": `Experience the pinnacle of elegance in our exclusive villas, where privacy and luxury blend seamlessly. These homes boast spacious interiors, premium finishes, and modern amenities for the discerning. Located in prestigious areas, they offer a serene retreat close to essential conveniences. \n `,
    "imageUrl": "/img/others/10.png",
    "details": [
      { "label": "Living Area", "value": "2800 Sq. Ft" },
      { "label": "Master Bedroom", "value": "160 Sq. Ft" },
      { "label": "Ensuite Bathroom", "value": "50 Sq. Ft" },
      { "label": "Balcony Space", "value": "75 Sq.Ft" },
      { "label": "Dining Area", "value": "700 Sq. Ft" }
    ]
  },
  "second": {
    "title": "Premium Apartments",
    "description": "Live in style with our modern apartments that combine innovative design, top-tier amenities, and prime locations for the ultimate urban lifestyle.",
    "imageUrl": "/img/others/10.png",
    "details": [
      { "label": "Total Space", "value": "3000 Sq. Ft" },
      { "label": "Guest Bedroom", "value": "140 Sq. Ft" },
      { "label": "Bath with Tub", "value": "60 Sq. Ft" },
      { "label": "Outdoor Terrace", "value": "85 Sq.Ft" },
      { "label": "Open Kitchen", "value": "800 Sq. Ft" }
    ]
  },
  "third": {
    "title": "Open Plots & Villa Plots",
    "description": "Create your dream home on our well-situated open plots and villa plots, offering flexibility and excellent potential for future growth.",
    "imageUrl": "/img/others/10.png",
    "details": [
      { "label": "Overall Area", "value": "3500 Sq. Ft" },
      { "label": "King Suite", "value": "180 Sq. Ft" },
      { "label": "Luxury Bathroom", "value": "70 Sq. Ft" },
      { "label": "Private Balcony", "value": "100 Sq.Ft" },
      { "label": "Entertainment Lounge", "value": "900 Sq. Ft" }
    ]
  },
  "fourth": {
    "title": "Commercial Spaces",
    "description": "Give your business a strategic advantage with our prime commercial properties, located in key areas to maximize visibility and accessibility.",
    "imageUrl": "/img/others/10.png",
    "details": [
      { "label": "Garden Area", "value": "4000 Sq. Ft" },
      { "label": "Herb Garden", "value": "120 Sq. Ft" },
      { "label": "Jacuzzi Bath", "value": "80 Sq. Ft" },
      { "label": "Sky Deck", "value": "150 Sq.Ft" },
      { "label": "BBQ Area", "value": "950 Sq. Ft" }
    ]
  },
  "five": {
    "title": "Farm Plots & Farmhouses",
    "description": "Embrace the serenity of nature with our farm plots and farmhouses, perfect for those seeking a peaceful retreat or a sound investment in agricultural land.",
    "imageUrl": "/img/others/10.png",
    "details": [
      { "label": "Duplex Area", "value": "3200 Sq. Ft" },
      { "label": "Gallery Space", "value": "200 Sq. Ft" },
      { "label": "Spa Bathroom", "value": "90 Sq. Ft" },
      { "label": "Rooftop Terrace", "value": "110 Sq.Ft" },
      { "label": "Double Height Living Room", "value": "1000 Sq. Ft" }
    ]
  }
};

  const properties = [
    {
      image: FeedImages.feed1,
      title: 'Luxury Villa in Bali',
      location: 'Bali, Indonesia',
      link: '#',
    },
    {
      image: FeedImages.feed1,
      title: 'Modern Apartment in NYC',
      location: 'New York City, USA',
      link: '#',
    },
    {
      image: 'https://via.placeholder.com/300x200',
      title: 'Cozy House in Canada',
      location: 'Toronto, Canada',
      link: '#',
    },
    {
      image: 'https://via.placeholder.com/300x200',
      title: 'Stylish Condo in Mexico',
      location: 'Mexico City, Mexico',
      link: '#',
    },
    {
      image: 'https://via.placeholder.com/300x200',
      title: 'Luxury Villa in Bali',
      location: 'Bali, Indonesia',
      link: '#',
    },
    {
      image: 'https://via.placeholder.com/300x200',
      title: 'Modern Apartment in NYC',
      location: 'New York City, USA',
      link: '#',
    },
  ];


  const aminities = [
    {
      icon: AminitiesImages.car, // Icon URL
      number: '01',
      title: 'Parking Space',
      description: 'Enimad minim veniam quis no exercitation ullamco lab.',
    },
    {
      icon: AminitiesImages.swimming, // Icon URL
      number: '02',
      title: 'Swimming Pool',
      description: 'Enimad minim veniam quis no exercitation ullamco lab.',
    },
    {
      icon: AminitiesImages.shield, // Icon URL
      number: '03',
      title: 'Private Security',
      description: 'Enimad minim veniam quis no exercitation ullamco lab.',
    },
    {
      icon: AminitiesImages.stethoscope, // Icon URL
      number: '04',
      title: 'Medical Center',
      description: 'Enimad minim veniam quis no exercitation ullamco lab.',
    },
    {
      icon: AminitiesImages.playground, // Icon URL
      number: '05',
      title: 'Kids Playground',
      description: 'Enimad minim veniam quis no exercitation ullamco lab.',
    },
    {
      icon: AminitiesImages.bedking, // Icon URL
      number: '06',
      title: 'King Size Beds',
      description: 'Enimad minim veniam quis no exercitation ullamco lab.',
    },
    {
      icon: AminitiesImages.books, // Icon URL
      number: '07',
      title: 'Library',
      description: 'Enimad minim veniam quis no exercitation ullamco lab.',
    },
    {
      icon: AminitiesImages.homelove, // Icon URL
      number: '08',
      title: 'Smart Homes',
      description: 'Enimad minim veniam quis no exercitation ullamco lab.',
    }
  ];

  const testimonialData = [
    {
      img: 'testimonial-1.jpg',
      name: 'Anjali Sharma',
      type: 'Homeowner',
      description: 'Realty Yards helped us find our dream home with ease. Their professionalism and attention to detail were exceptional'
    },
    {
      img: 'testimonial-2.jpg',
      name: 'Vikram Singh',
      type: 'Business Owner',
      description: 'The team is knowledgeable and trustworthy. They guided us through a complex commercial deal with great expertise.'
    },
    {
      img: 'testimonial-2.jpg',
      name: 'Anil Kumar',
      type: 'Businessman',
      description: 'From start to finish, they provided exceptional service and helped me find the perfect commercial space'
    },
    {
      img: 'testimonial-2.jpg',
      name: 'Kavita Rao',
      type: 'Doctor',
      description: 'As an NRI, finding the right property in Hyderabad was a challenge until I connected with Realty Yards.'
    },
  ];

  const FeedData = [
    {
      image: FeedImages.feed1, // Replace with actual image URLs
      title: '10 Brilliant Ways to Decorate Your Home',
      date: 'August 28, 2024',
      category: 'Interior',
      link: '/news/10-brilliant-ways-to-decorate-your-home', // Replace with actual link
    },
    {
      image: FeedImages.feed2, // Replace with actual image URLs
      title: '5 Tips to Improve Your Outdoor Space',
      date: 'August 30, 2024',
      category: 'Exterior',
      link: '/news/5-tips-to-improve-your-outdoor-space', // Replace with actual link
    },
    {
      image: FeedImages.feed3, // Replace with actual image URLs
      title: 'The Best Furniture for Your Home',
      date: 'September 2, 2024',
      category: 'Furniture',
      link: '/news/the-best-furniture-for-your-home', // Replace with actual link
    },
    {
      image: FeedImages.feed4, // Replace with actual image URLs
      title: 'How to Decorate Your Home on a Budget',
      date: 'September 5, 2024',
      category: 'Budget',
      link: '/news/how-to-decorate-your-home-on-a-budget', // Replace with actual link
    },
    {
      image: FeedImages.feed5, // Replace with actual image URLs
      title: 'The Most Beautiful Architectural Designs',
      date: 'September 10, 2024',
      category: 'Architecture',
      link: '/news/the-most-beautiful-architectural-designs', 
    },
  ];

  const Dashboard: React.FC = () => {

  
    return (
      <div>

        <HeroSectionStyle data={heroData} />
        <AboutUsSectionOne imageSrc={temp} />
        <AboutUsSectionTwo />
        <Feature data={featureData} titleSectionData={titleSectionData} />
        <AboutUSInfo />
        <ApartmentPlan tabData={apartmentsPlanData} />
        {/* <PropertyCardSlider properties={properties} /> */}
        <Aminities cards={aminities} />
        <LatestNews articles={FeedData} />
        <Testimonial testimonialData={testimonialData} />
      </div>
    );
  };
  
  export default Dashboard;
