import React from 'react';
import { Box, Card, CardContent, CardMedia, Typography, Grid, IconButton, Divider, Pagination, Select, MenuItem } from '@mui/material';
import { LocationOn, Favorite } from '@mui/icons-material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { FeedImages } from '../../assets';
import { useNavigate } from 'react-router-dom';
import ShareButton from '../../components/Share/Share';

interface Project {
    _id: string;
    builderImage: string;
    builderName: string;
    builderOccupation: string;
    projectName: string;
    projectLocation: string;
    projectBHK: number[];
    unitsApartments: string;
    size: string;
    approvals: string;
    category: string;
    amenities: string[];
    locationHighlights: string;
    floorPlans: string[] | null;
    projectElevations: string[] | null;
    propertyType: string;
    priceRange: string;
    bhk: number;
    bath: number;
    createdAt: string;
    updatedAt: string;
    price: number;
    squareFeet: number;
    projectImages: string[];
}

interface ProjectsGridProps {
    paginatedData: Project[];
    currentPage: number;
    totalPages: number;
    setCurrentPage: (page: number) => void;
    itemsPerPage: number;
    handleChangeItemsPerPage: (value: number) => void;
}

const ProjectsGrid: React.FC<ProjectsGridProps> = ({
    paginatedData,
    currentPage,
    totalPages,
    setCurrentPage,
    itemsPerPage,
    handleChangeItemsPerPage,
}) => {

    const navigate = useNavigate();

    const formatNumberIndian = (num: number) => {
        return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(num);
    };

    const handleCardClick = (_id: string) => {
        navigate(`/projects/${_id}`);
      };

      const urlToShare = "https://realtyyards.com/projects";
      const titleToShare = "Check out this amazing website!";

    return (
        <Box>
            {paginatedData.length === 0 ? (
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Box sx={{ width: { xs: '85%', sm: '50%', md: '33%', lg: '30%' }, margin: '0 auto' }}>
                        <img src={FeedImages.nodata} alt="No Properties Available" style={{ width: "100%", height: 'auto' }} />
                    </Box>
                    <Typography variant="h4" align="center" sx={{ mt: 2, color: 'text.secondary', fontWeight: '600' }}>
                        No Properties Available
                    </Typography>
                </Box>
            ) : (
                <>
                       <Grid container spacing={3} justifyContent="flex-start">
                        {paginatedData.map((project) => (
                            <Grid item xs={12} sm={6} md={4} lg={2.4} key={project._id}>
                            <Card
                                sx={{
                                position: 'relative',
                                boxShadow: '0px 5px 15px rgba(0,0,0,0.2)',
                                borderRadius: '10px',
                                cursor: 'pointer',
                                transition: 'all 0.3s ease-in-out',
                                '&:hover': {
                                    boxShadow: '0px 5px 15px rgba(0,0,0,0.4)',
                                    transform: 'translateY(-8px)',
                                },
                                }}
                            >
                                {/* Top Part */}
                                <CardMedia
                                component="img"
                                height="160"
                                image={`https://api.realtyyards.com/uploads/${project.projectImages[0]}`}
                                alt={`Project: ${project.projectName}`}
                                onClick={() => handleCardClick(project._id)}
                                />

                                {/* Bottom Part */}
                                <CardContent
                                sx={{
                                    px: { xs: 1.5, sm: 2 },
                                    py: { xs: 1.5, sm: 2 },
                                    textAlign: 'left',
                                }}
                                >
                                <Typography
                                    variant="caption"
                                    color="primary"
                                    sx={{ fontWeight: 500 }}
                                    onClick={() => handleCardClick(project._id)}
                                >
                                    {project.propertyType}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{ fontWeight: 700, mb: 1 }}
                                    onClick={() => handleCardClick(project._id)}
                                >
                                    {project.projectName}
                                </Typography>

                                <Box display="flex" alignItems="center" gap={1} mb={0.5}>
                                    <LocationOn fontSize="small" color="primary" />
                                    <Typography variant="caption" color="text.secondary">
                                    {project.projectLocation}
                                    </Typography>
                                </Box>

                                <Typography
                                    variant="body2"
                                    color="primary"
                                    sx={{ fontWeight: 600 }}
                                >
                                    <b>₹ {formatNumberIndian(project.price)}</b>/sft
                                </Typography>

                                <Divider sx={{ my: 1.5 }} />

                                {/* Action Buttons */}
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <IconButton
                                    size="small"
                                    sx={{
                                        backgroundColor: '#f5f5f5',
                                        height: { xs: '30px', sm: '35px' },
                                        width: { xs: '30px', sm: '35px' },
                                        borderRadius: '2px',
                                        '&:hover': {
                                        backgroundColor: '#da2e3170',
                                        color: 'white',
                                        },
                                    }}
                                    onClick={() => handleCardClick(project._id)}
                                    >
                                    <OpenInFullIcon fontSize="small" />
                                    </IconButton>

                                    <IconButton
                                    size="small"
                                    sx={{
                                        backgroundColor: '#f5f5f5',
                                        height: { xs: '30px', sm: '35px' },
                                        width: { xs: '30px', sm: '35px' },
                                        borderRadius: '2px',
                                        '&:hover': {
                                        backgroundColor: '#da2e3170',
                                        color: 'white',
                                        },
                                    }}
                                    >
                                    <Favorite fontSize="small" />
                                    </IconButton>

                                    <IconButton
                                    size="small"
                                    sx={{
                                        backgroundColor: '#f5f5f5',
                                        height: { xs: '30px', sm: '35px' },
                                        width: { xs: '30px', sm: '35px' },
                                        borderRadius: '2px',
                                        '&:hover': {
                                        backgroundColor: '#da2e3170',
                                        color: 'white',
                                        },
                                    }}
                                    >
                                    <ShareButton
                                        shareUrl={`${urlToShare}/${project._id}`}
                                        shareTitle={titleToShare}
                                    />
                                    </IconButton>
                                </Box>
                                </CardContent>
                            </Card>
                            </Grid>
                        ))}
                        </Grid> 

                    {/* Pagination and Rows per Page */}
                    <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="20px">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={(event, value) => setCurrentPage(value)}
                                    shape="rounded"
                                    sx={{
                                        "& .MuiPaginationItem-root": {
                                            color: 'primary.main',
                                            height: "27px",
                                            minWidth: "27px",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            margin: "0 4px",
                                        },
                                        "& .MuiPaginationItem-page.Mui-selected": {
                                            backgroundColor: 'primary.main',
                                            color: 'primary.contrastText',
                                        },
                                        "& .MuiPaginationItem-page.Mui-selected:hover": {
                                            backgroundColor: 'primary.main',
                                            color: 'primary.contrastText',
                                        },
                                        "& .Mui-selected": {
                                            backgroundColor: 'primary.main',
                                            color: 'primary.contrastText',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
                                Rows per page:
                                <Select
                                    value={itemsPerPage}
                                    onChange={(e) => handleChangeItemsPerPage(Number(e.target.value))}
                                    variant="outlined"
                                    style={{
                                        width: "auto",
                                        height: "25px",
                                        background: "#FFFFFF",
                                        borderRadius: "5px",
                                        textAlign: "center",
                                        lineHeight: "20px",
                                        marginLeft: "10px",
                                        outline: "none",
                                        boxShadow: "none",
                                    }}
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                    <MenuItem value={150}>150</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ProjectsGrid;
