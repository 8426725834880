// src/components/BlogPage.tsx
import React from 'react';
import { Card, CardContent, CardMedia, Typography, Button, Grid, Container, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FeedImages } from '../../assets';
import TopHeading from '../../components/Top/TopHeading';
import Testimonial from '../Dashboard/Testimonial';

interface Blog {
  id: number;
  imageUrl: string;
  caption: string;
  title: string;
}

const blogData: Blog[] = [
  {
    id: 1,
    imageUrl: FeedImages.feed1,
    caption: 'Technology',
    title: 'The Rise of AI in Modern Technology',
  },
  {
    id: 2,
    imageUrl: FeedImages.feed2,
    caption: 'Travel',
    title: 'Top 10 Travel Destinations for 2024',
  },
  {
    id: 3,
    imageUrl: FeedImages.feed3,
    caption: 'Health',
    title: 'How to Maintain a Healthy Lifestyle',
  },
];


const testimonialData = [
  {
    img: 'testimonial-1.jpg',
    name: 'Anjali Sharma',
    type: 'Homeowner',
    description: 'Realty Yards helped us find our dream home with ease. Their professionalism and attention to detail were exceptional'
  },
  {
    img: 'testimonial-2.jpg',
    name: 'Vikram Singh',
    type: 'Business Owner',
    description: 'The team is knowledgeable and trustworthy. They guided us through a complex commercial deal with great expertise.'
  },
  {
    img: 'testimonial-2.jpg',
    name: 'Anil Kumar',
    type: 'Businessman',
    description: 'From start to finish, they provided exceptional service and helped me find the perfect commercial space'
  },
  {
    img: 'testimonial-2.jpg',
    name: 'Kavita Rao',
    type: 'Doctor',
    description: 'As an NRI, finding the right property in Hyderabad was a challenge until I connected with Realty Yards.'
  },
];


const BlogPage: React.FC = () => {

  const navigate = useNavigate();

  const handleReadMore = (id: number) => {
    navigate(`/blog/${id}`);
  };

  return (
   <>
    <TopHeading value="Blog" />
    <Box sx={{ 
      px :{ xs : 2 , sm : 3 , md : 5 , lg : 6 } , 
      py : 1 ,
      flexGrow: 1,
    }}>
    <Container>
      {/* <Typography variant="h4" component="h1" gutterBottom>
        Blog Grid
      </Typography> */}
      <Grid container spacing={4}>
        {blogData.map((blog) => (
          <Grid item xs={12} sm={6} md={4} key={blog.id}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                image={blog.imageUrl}
                alt={blog.title}
                sx={{ height: 200, objectFit: 'cover' }} // Fixed height and cover fit
              />
              <CardContent sx={{ height: '40%' }}>
                <Typography variant="caption" color="text.secondary" sx={{ display: 'block', marginBottom: '8px', textAlign:'left' }}>
                  {blog.caption}
                </Typography>
                <Typography variant="body1" component="div" sx={{ marginBottom: '16px',textAlign:'left' }}>
                  {blog.title}
                </Typography>
                <Button size="small" variant="contained" color="primary" sx={{textAlign:'left'}} onClick={() => handleReadMore(blog.id)}>
                  Read More
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>

    </Box>
    <Testimonial testimonialData={testimonialData} />
    </>
  );
};

export default BlogPage;



// import React from 'react';
// import { Container, Grid, Typography, Paper, Box, Divider } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { FeedImages } from '../../assets';

// // Styled components for the timeline
// const TimelineContainer = styled(Container)(({ theme }) => ({
//   position: 'relative',
//   padding: theme.spacing(4),
//   overflow: 'hidden',
// }));

// const TimelineLine = styled('div')(({ theme }) => ({
//   position: 'absolute',
//   left: '50%',
//   top: 0,
//   bottom: 0,
//   width: '5px',
//   backgroundColor: theme.palette.primary.main,
//   transform: 'translateX(-50%)',
//   zIndex: 1,
// }));

// const TimelineItem = styled(Grid)(({ theme }) => ({
//   position: 'relative',
//   padding: theme.spacing(2),
//   '&:nth-of-type(odd)': {
//     paddingLeft: theme.spacing(8),
//     textAlign: 'right',
//     '&::before': {
//       content: '""',
//       position: 'absolute',
//       left: '-12px',
//       top: '50%',
//       width: '10px',
//       height: '10px',
//       backgroundColor: theme.palette.primary.main,
//       borderRadius: '50%',
//       transform: 'translateY(-50%)',
//       zIndex: 2,
//     },
//     '& img': {
//       animation: 'fadeInLeft 1s ease-out',
//     },
//   },
//   '&:nth-of-type(even)': {
//     paddingRight: theme.spacing(8),
//     textAlign: 'left',
//     '&::before': {
//       content: '""',
//       position: 'absolute',
//       right: '-12px',
//       top: '50%',
//       width: '10px',
//       height: '10px',
//       backgroundColor: theme.palette.primary.main,
//       borderRadius: '50%',
//       transform: 'translateY(-50%)',
//       zIndex: 2,
//     },
//     '& img': {
//       animation: 'fadeInRight 1s ease-out',
//     },
//   },
// }));

// const TimelineImage = styled(Paper)(({ theme }) => ({
//   width: '100%',
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[3],
//   overflow: 'hidden',
// }));

// const TimelineContent = styled(Box)(({ theme }) => ({
//   padding: theme.spacing(2),
//   background: '#fff',
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: theme.shadows[2],
// }));

// // Keyframes for animations
// const keyframes = `
// @keyframes fadeInLeft {
//   from {
//     opacity: 0;
//     transform: translateX(-20px);
//   }
//   to {
//     opacity: 1;
//     transform: translateX(0);
//   }
// }

// @keyframes fadeInRight {
//   from {
//     opacity: 0;
//     transform: translateX(20px);
//   }
//   to {
//     opacity: 1;
//     transform: translateX(0);
//   }
// }
// `;

// const BlogPage: React.FC = () => {
//   return (
//     <TimelineContainer>
//       <style>{keyframes}</style>
//       {/* <TimelineLine /> */}

//       {/* Blog Post 1 */}
//       {/* <TimelineItem container spacing={4} alignItems="center">
//         <Grid item xs={12} sm={4}>
//           <TimelineImage elevation={3}>
//             <Box 
//                 sx={{
//                 position: 'relative',
//                 paddingTop: '56.25%',
//                 '& img': {
//                     position: 'absolute',
//                     top: 0,
//                     left: 0,
//                     width: '100%',
//                     height: '100%',
//                     objectFit: 'cover',
//                 },
//             }}
//             >
//             <img
//               src={FeedImages.feed2}
//               alt="Image 1"
//               style={{ width: '100%', height: 'auto' }}
//             />
//             </Box>
//           </TimelineImage>
//         </Grid>
//         <Grid item xs={12} sm={8}>
//           <TimelineContent>
//             <Typography variant="h6" gutterBottom sx={{textAlign:'left'}}>
//               Blog Post Title 1
//             </Typography>
//             <Typography variant="body1" gutterBottom sx={{textAlign:'left'}}>
//               This is a brief description of the blog post content, outlining key takeaways or the topic of discussion.
//             </Typography>
//             <Typography variant="caption" sx={{textAlign:'left'}}>Date: August 12, 2024</Typography>
//           </TimelineContent>
//         </Grid>
//       </TimelineItem>

//       <Divider sx={{ my: 4 }} /> */}


//       <TimelineItem container spacing={4} alignItems="center">
//         <Grid item xs={12} sm={8}>
//           <TimelineContent>
//             <Typography variant="h6" gutterBottom sx={{textAlign:'left'}}>
//               Blog Post Title 1
//             </Typography>
//             <Typography variant="body1" gutterBottom sx={{textAlign:'left'}}>
//               This is a brief description of another blog post, showcasing a different angle or topic of interest.
//             </Typography>
//             <Typography variant="caption" paragraph sx={{textAlign:'right'}}>Date: September 5, 2024</Typography>
//           </TimelineContent>
//         </Grid>
//         <Grid item xs={12} sm={4}>
//           <TimelineImage elevation={3}>
//           <Box 
//                 sx={{
//                 position: 'relative',
//                 paddingTop: '56.25%',
//                 '& img': {
//                     position: 'absolute',
//                     top: 0,
//                     left: 0,
//                     width: '100%',
//                     height: '100%',
//                     objectFit: 'cover',
//                 },
//             }}
//             >
//             <img
//               src={FeedImages.feed1}
//               alt="Image 2"
//               style={{ width: '100%', height: 'auto' }}
//             />
//             </Box>
//           </TimelineImage>
//         </Grid>
//       </TimelineItem>


//       <Divider sx={{ my: 4 }} />

// {/* 
//         <TimelineItem container spacing={4} alignItems="center">
//             <Grid item xs={12} sm={4}>
//             <TimelineImage elevation={3}>
//             <Box 
//                     sx={{
//                     position: 'relative',
//                     paddingTop: '56.25%',
//                     '& img': {
//                         position: 'absolute',
//                         top: 0,
//                         left: 0,
//                         width: '100%',
//                         height: '100%',
//                         objectFit: 'cover',
//                     },
//                 }}
//                 >
//                 <img
//                 src={FeedImages.feed3}
//                 alt="Image 3"
//                 style={{ width: '100%', height: 'auto' }}
//                 />
//                 </Box>
//             </TimelineImage>
//             </Grid>
//             <Grid item xs={12} sm={8}>
//             <TimelineContent>
//                 <Typography variant="h6" gutterBottom sx={{textAlign:'left'}}>
//                 Blog Post Title 3
//                 </Typography>
//                 <Typography variant="body1" gutterBottom sx={{textAlign:'left'}}>
//                 This is a brief description of a third blog post, highlighting a different aspect or perspective.
//                 </Typography>
//                 <Typography variant="caption" sx={{textAlign:'left'}}>Date: October 18, 2024</Typography>
//             </TimelineContent>
//             </Grid>
//         </TimelineItem> */}


//       {/* Add more blog posts following the same structure */}
//     </TimelineContainer>
//   );
// };

// export default BlogPage;
