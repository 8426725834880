// Slideshow.tsx
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

interface SlideshowProps {
  images: string[];
}

const Slide = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  transition: 'opacity 1s ease-in-out',
}));

const Slideshow: React.FC<SlideshowProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <Box sx={{ position: 'relative', width: 300, height: 200, overflow: 'hidden' }}>
      {images.map((image, index) => (
        <Slide
          key={index}
          sx={{ opacity: index === currentIndex ? 1 : 0 }}
        >
          <img src={image} alt={`Slide ${index}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </Slide>
      ))}
    </Box>
  );
};

export default Slideshow;
