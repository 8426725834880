import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { About, Blog, ContactUs, BlogDetail,  Gallery, Landing, NotFound, NRI, ProjectDetails, Projects, Selector, Dashboard } from '../Pages/Exports';
import Layout from '../Layout/Layout';

const router = createBrowserRouter([

    {
      path: '/',
      element: <Landing />,
      errorElement: <NotFound />, 
    },
    {
      path : "/selector",
      element : <Selector />,
    },
    {
    element: <Layout />,
    children: [
      {
        path: "/home",
        element: <Dashboard />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        //projects
        path: "/projects",
        element: <Projects />,
      },
      {
        path : "/projects/:id",
        element : <ProjectDetails />,
      },

      {
        path : "/blog",
        element : <Blog />,
      },

      {
        path : "/blog/:id",
        element : <BlogDetail />,
      },
      //contact
      {
        path : "/contact",
        element : <ContactUs />,
      },
      {
        path : "/nri",
        element : <NRI />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
