// src/components/BlogDetail.tsx
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Container, Button, Box, Paper, Divider } from '@mui/material';

const blogDetails = [
  {
    id: 1,
    title: 'The Rise of AI in Modern Technology',
    content: 'Artificial Intelligence (AI) is transforming the way we live and work. From autonomous vehicles to personal assistants, AI is increasingly becoming a part of our daily lives...',
    author: 'John Doe',
    date: 'September 20, 2024',
    tags: ['AI', 'Technology', 'Innovation'],
  },
  {
    id: 2,
    title: 'Top 10 Travel Destinations for 2024',
    content: 'As the world opens up, travelers are eager to explore new destinations. Here are the top ten places you should visit in 2024...',
    author: 'Jane Smith',
    date: 'August 15, 2024',
    tags: ['Travel', 'Adventure', 'Destinations'],
  },
  {
    id: 3,
    title: 'How to Maintain a Healthy Lifestyle',
    content: 'Living a healthy lifestyle is crucial for longevity and happiness. This blog provides practical tips on diet, exercise, and mental health...',
    author: 'Emily Johnson',
    date: 'July 10, 2024',
    tags: ['Health', 'Wellness', 'Lifestyle'],
  },
  // More blog detail entries can be added here...
];

const BlogDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const blog = blogDetails.find(b => b.id === Number(id));

  if (!blog) {
    return <Typography variant="h6">Blog not found</Typography>;
  }

  const goBack = () => {
    navigate('/blog');
  };

  return (
    <Container sx={{ marginTop: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {blog.title}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
        By {blog.author} | {blog.date}
      </Typography>
      <Paper elevation={3} sx={{ padding: 3, marginBottom: 2 }}>
        <Typography variant="body1" paragraph>
          {blog.content}
        </Typography>
      </Paper>
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="h6" gutterBottom>
          Tags:
        </Typography>
        {blog.tags.map(tag => (
          <Button key={tag} variant="outlined" size="small" sx={{ marginRight: 1 }}>
            {tag}
          </Button>
        ))}
      </Box>
      <Divider sx={{ margin: '20px 0' }} />
      <Button variant="contained" color="primary" onClick={goBack}>
        Back to Blog List
      </Button>
    </Container>
  );
};

export default BlogDetail;

