import React, { useEffect, useState } from 'react';
import TopHeading from '../../components/Top/TopHeading';
import ContactUsDetails from './ContactUsDetails';
import ContactUsForm from './ContactUsForm';
import ContactUsIFrame from './ContactUsIFrame';
import ThreeFormsModal from '../../components/ModalForm/ThreeFormsModal';
import Cookies from 'js-cookie';

const ContactUs: React.FC = () => {
    return (
        <>  
            <TopHeading value="Contact Us" />
            <ContactUsDetails />
            <ContactUsIFrame />
        </>
    );
};

export default ContactUs;
