import React from 'react';
import { Container, Typography, Box, useTheme } from '@mui/material';

const ExperienceSection: React.FC = () => {
  const theme = useTheme();

  return (
    <Box sx={customStyles.experienceSection(theme)}>
        <Box sx={{
           px :{ xs : 2 , sm : 4 , md : 6 , lg : 8 } , py : 4 ,
        }}>
          <Typography variant="h4" sx={customStyles.heading}>
            Our Experience
          </Typography>
          <Typography variant="h5" sx={customStyles.subheading}>
            Decades of Excellence in Real Estate
          </Typography>
          <Typography variant="body2" sx={customStyles.bodyText}>
            With over four decades of experience, Realty Yards is one of the most trusted real estate firms in Hyderabad.
            Our deep understanding of the market allows us to offer our clients the best real estate services in Hyderabad.
            Our portfolio includes some of the most prestigious residential and commercial projects, making us the go-to
            choice for discerning buyers and investors.
          </Typography>
        </Box>
    </Box>
  );
};

export default ExperienceSection;

const customStyles = {
  experienceSection: (theme: any) => ({
    background: `#ffffff`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    boxShadow: '0px -10px 10px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'left' as const,
    marginTop : 2,
  }),

  heading: {
    marginBottom: '15px',
    textAlign: 'left' as const,
    fontWeight: 700,
  },
  subheading: {
    marginBottom: '15px',
    fontWeight: 500,
    color: "primary.main",  
  },
  bodyText: {
    margin: '0 auto',
    fontWeight: 400,
    lineHeight: 1.8,
  },
};
