import React from 'react';
import TopHeading from '../../components/Top/TopHeading';
import NRITabs from './NRITabs';

const NRI: React.FC = () => {
    return (
        <>
        <TopHeading value="NRI" />
        <NRITabs />
        </>
    );
};

export default NRI;