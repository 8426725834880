import React from 'react';
import { Container, Typography, Box, IconButton, useTheme } from '@mui/material';
import Slider from 'react-slick';
import { FaStar } from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// Define custom arrow components

const CustomArrow = ({ className, style, onClick }: any) => (
  <IconButton
    className={className}
    style={{
      ...style,
      backgroundColor: '#ffffff',
      border: '2px solid #ffffff',
      color: '#da2e31',
      width: '50px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
      position: 'absolute',
      top: '50%',
      zIndex: 1,
      transform: 'translateY(-50%)',
      padding: 0, // Remove any padding from the IconButton to ensure perfect centering
    }}
    onClick={onClick}
  >
    {
      className.includes('slick-next') 
        ? <ArrowForwardIosIcon style={{ fontSize: '24px' }} /> 
        : <ArrowBackIosIcon style={{ fontSize: '24px' }} />
    }
  </IconButton>
);

const TestimonialCarouselItem = ({ data }: { data: any }) => (
  <Box
    sx={{
      padding: '30px',
      // backgroundColor: 'rgba(255, 255, 255, 0.85)',
      color: '#333',
      borderRadius: '12px',
      textAlign: 'center',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      boxShadow: '0px 5px 15px rgba(0,0,0,0.1)',
      transition: 'transform 0.3s ease-in-out',
      '&:hover': {
        transform: 'translateY(-10px)',
      },
      margin : '10px 20px'
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
      }}
    >
      {/* <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          backgroundColor: '#da2e31',
          color: '#ffffff',
          padding: '10px 20px',
          borderRadius: '50%',
          width: '80px',
          height: '80px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '24px',
        }}
      >
        {data.name.charAt(0)}
      </Typography> */}
    </Box>

    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
      {data.name}
    </Typography>
    <Typography variant="body2" sx={{ marginBottom: '8px', color: '#666' }}>
      {data.type}
    </Typography>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '16px',
      }}
    >
      {[...Array(5)].map((_, i) => (
        <FaStar key={i} style={{ color: '#da2e31', margin: '0 2px' }} />
      ))}
    </Box>
    <Typography variant="body2" sx={{ color: '#555' }}>
      "{data.description}"
    </Typography>
  </Box>
);

interface TestimonialProps {
  testimonialData: {
    img?: string;
    name: string;
    type: string;
    description: string;
  }[];
}

const Testimonial: React.FC<TestimonialProps> = ({ testimonialData }) => {
  const theme = useTheme();

  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <CustomArrow className="slick-next" />,
    prevArrow: <CustomArrow className="slick-prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        // backgroundColor: theme.palette.background.default,
        px :{ xs : 2 , sm : 4 , md : 6 , lg : 8 } , py : 4 ,
        textAlign: 'center',
      }}
    >
      <Container>
        <Box sx={{ marginBottom: '40px' }}>
          <Typography variant="h6" color="primary">
            Client's Testimonial
          </Typography>
          <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: '8px', color: theme.palette.text.primary }}>
            Hear from Our Satisfied Clients
          </Typography>
        </Box>
        <Slider {...settings} className="testimonial-slider">
          {testimonialData.map((item, index) => (
            <Box key={index} sx={{ padding: '0px' }}>
              <TestimonialCarouselItem data={item} />
            </Box>

          ))}
        </Slider>
      </Container>
    </Box>
  );
};

export default Testimonial;
