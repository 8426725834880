// src/components/AboutUSInfo.tsx

import React from 'react';
import { Box, Typography, Grid, useTheme } from '@mui/material';
import { Aboutus1, home1 } from '../../assets'; // Ensure the correct path to your images

const AboutUSInfo: React.FC = () => {
  const theme = useTheme();

  return (
    
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        px: { xs: 2, sm: 4, md: 6, lg: 8 },
        py: 4,
        alignItems: 'stretch',
        backgroundColor: '#ffffff',
      }}
    >
      {/* Left Side - Image */}
      <Box sx={{ flex: 1, paddingRight: { md: theme.spacing(2) }, marginBottom: { xs: theme.spacing(4), md: 0 } }}>
        <img
          src={Aboutus1}
          alt="Real Estate"
          style={{
            width: '100%',
            height: '100%',
            borderRadius: theme.shape.borderRadius,
            objectFit: 'cover',
          }}
        />
      </Box>

      {/* Right Side - Text Content */}
      <Box sx={{ flex: 1.5, display: 'flex', position: 'relative' }}>
        <Box
          sx={{
            paddingLeft: { xs: 0, md: theme.spacing(4) },
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            zIndex: 1,
            textAlign: 'left',
          }}
        >
          {/* About Us Badge */}
          <Box
            sx={{
              marginBottom: theme.spacing(2),
              backgroundColor: '#fc585b',
              padding: theme.spacing(1),
              borderRadius: '12px',
              width: 'fit-content',
            }}
          >
            <Typography variant="body1" color="#ffffff" sx={{ fontWeight: 'bold' }}>
              About Us
            </Typography>
          </Box>

          {/* Heading */}
          <Typography variant="h3" gutterBottom sx={{ fontWeight: 700 }}>
            Your Premier Partner in Real Estate Excellence
          </Typography>

          {/* Description */}
          <Typography variant="caption" paragraph sx={{ textAlign: 'justify' }}>
            At Realty Yards, we’re not just building properties but a place we call it a home crafting lasting legacies. With over four decades of experience in Hyderabad's dynamic real estate market, we specialize in delivery your perfect haven that reflect sophistication quality and match your target.
          </Typography>

          {/* Icon and Text Section */}
          <Grid container spacing={2}>
            {[
              '40+ years of leadership',
              'Expertise in luxury properties',
              'Tailored real estate solutions',
              'Commitment to quality and innovation',
            ].map((text, index) => (
              <Grid item xs={6} key={index}>
                <Box display="flex" alignItems="center">
                  <img
                    src={home1}
                    alt={text}
                    style={{
                      marginRight: theme.spacing(1),
                      width: 20,
                      height: 20,
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography variant="caption">{text}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>

          {/* Additional Description */}
          <Box sx={{ marginTop: theme.spacing(4) }}>
            <Typography variant="caption" paragraph sx={{ textAlign: 'justify' }}>
              Our diverse portfolio includes luxury villas, premium apartments, and strategic commercial investments, each designed to elevate your lifestyle and secure your future. Whether you're a discerning homebuyer or an astute investor, Realty Yards is your trusted partner in navigating the best real estate opportunities in Hyderabad. Experience the Realty Yards difference—where your dreams find a home.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUSInfo;
