import React, { useState } from 'react';
import { Container, Grid, Typography, IconButton, Box, Dialog, DialogContent, Icon, Divider } from '@mui/material';
import { FaPlay } from 'react-icons/fa';
import { CheckCircle } from '@mui/icons-material';
import { section2 } from '../../assets'; 
import './style.css'; // Ensure you have the relevant styles here
import CloseIcon from '@mui/icons-material/Close';

const AboutUsSectionTwo: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {/* Video Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderRadius: 0,
            height: 'calc(100vh - 100px)',
          },
        }}
      >
        <DialogContent
          style={{
            padding: 0,
            position: 'relative',
            height: '100%',
          }}
        >
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/8WakPPT42lw?autoplay=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <IconButton
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              color: '#FFF',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              animation: 'pulse1 1s infinite',
            }}
          >
            <CloseIcon />
            {/* <Icon>close</Icon> */}
          </IconButton>
        </DialogContent>
      </Dialog>

      {/* Section Content */}
      <Container maxWidth="lg" sx={{ px :{ xs : 2 , sm : 4 , md : 6 , lg : 8 } , py : 4 , mt:2}}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
              <Box mb={2} sx={{ textAlign: 'left' }}>
                <Typography variant="body2" color="primary.main" sx={{ fontWeight: 500 }}>
                  Building Facilities
                </Typography>
                <Typography variant="h3" mb={2} sx={{ fontWeight: 700, color: '#000' }}>
                  A Legacy of Trust and Excellence
                </Typography>
                <Typography variant="caption" paragraph sx={{ textAlign: 'justify' }}>
                With over 40 years of unparalleled experience in the real estate industry, Realty Yards has established itself as the best real estate company in Hyderabad and one of the top real estate companies in Hyderabad. 
                </Typography>
                <Typography variant="caption" paragraph sx={{ textAlign: 'justify' }}>
                Our deep-rooted expertise and unwavering commitment to excellence make us the go-to choice for all your property needs, whether you're looking to buy your dream home or make a sound investment in prime real estate. 
                </Typography>
                <Typography variant="caption" paragraph sx={{ textAlign: 'justify' }}>
                As one of the leading real estate companies in Hyderabad, we cater to discerning home buyers and savvy investors across Hyderabad and Telangana.                </Typography>
              </Box>
              {/* <Box mb={4}>
                <ul style={{ padding: 0, listStyleType: 'none', margin: 0, display: 'flex', flexWrap: 'wrap' }}>
                  {[
                    "Living rooms are pre-wired for Surround",
                    "Luxurious interior design and amenities",
                    "Nestled in the Buckhead Vinings communities",
                    "Private balconies with stunning views",
                    "A rare combination of inspired architecture",
                    "Outdoor grilling with dining court",
                  ].map((item, index) => (
                    <li
                      key={index}
                      style={{
                        width: '100%',
                        maxWidth: 'calc(50% - 16px)',
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '12px',
                        paddingRight: '8px',
                        boxSizing: 'border-box',
                      }}
                    >
                      <Box
                        component="span"
                        style={{
                          fontSize: '24px',
                          color: '#da2e31', // Use primary color
                          marginRight: '12px',
                          height: '30px',
                          width: '30px',
                          lineHeight: '30px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '50%',
                        }}
                      >
                        <CheckCircle fontSize="inherit" />
                      </Box>
                      <Typography variant="caption" style={{ flex: 1, color: '#000' }}>
                        {item}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Box> */}
            </Box>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundImage: `url(${section2})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                width: '100%',
                height: { xs: '300px', lg: 'auto' },
                position: 'relative',
                marginTop: { xs: '50px', lg: 0 },
              }}
            >
              <img src={section2} alt="Building Facilities" style={{ width: '100%', height: 'auto', borderRadius: '10px' }} />

              {/* Play button centered within the Box */}
              <IconButton
                color="secondary"
                onClick={handleClickOpen}
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: '50%',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  animation: 'pulse2 1s infinite',
                  color: '#da2e31', // Use primary color
                  '&:hover': {
                    backgroundColor: '#da2e31',
                    color: '#fff',
                  },
                  p: 2.5,
                }}
              >
                <FaPlay style={{ fontSize: '24px' }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* <Divider sx={{ mt: 4 }} /> */}
    </>
  );
};

export default AboutUsSectionTwo;
