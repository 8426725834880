import React, { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { gsap } from 'gsap';
import { useNavigate } from 'react-router-dom';
import { MainVideo } from '../../assets'; 

const Landing: React.FC = () => {
  const navigate = useNavigate();
  const [isMute, setIsMute] = React.useState(false);

  // Function to handle button click animation and redirect
  const handleClick = () => {
    const tl = gsap.timeline();

    tl.to('.landing-button', {
      scale: 0.8,
      duration: 0.3,
      ease: 'power2.out',
      opacity: 0,
      onComplete: () => {
        gsap.to('.landing-triangle', {
          scaleX: 2,
          scaleY: 3,
          backgroundColor: '#da2e31', 
          borderLeft: '30vw solid transparent',
          borderRight: '30vw solid transparent',
          borderTop: '50vh solid transparent', 
          duration: 0.3,
          ease: 'power2.out',
          onComplete: () => {
            gsap.to('.landing-triangle', {
              backgroundColor: '#da2e31',
              duration: 0.4,
              ease: 'power2.out',
              onComplete: () => navigate('/projects') // Navigate to projects after animation
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    // Animate button and triangle appearance with fade-in effect
    const tl = gsap.timeline();

    tl.fromTo('.landing-triangle',
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1.5, ease: 'power2.out' }
    )
    .fromTo('.landing-button',
      { opacity: 0, scale: 0.8 },
      { opacity: 1, scale: 1, duration: 1.5, ease: 'elastic.out(1, 0.5)' },
      "-=1"
    );

    // Auto redirect after 25 seconds
    const timeout = setTimeout(() => {
      navigate('/projects');
    }, 24000);

    // Cleanup on unmount to avoid memory leaks
    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <Box sx={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      {/* Background Video */}
      <video autoPlay muted loop playsInline style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', zIndex: -1 }}>
        <source src={MainVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Landing Content */}
      <Box sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        textAlign: 'center',
        zIndex: 1
      }}>
        {/* Triangle and Button */}
        <Box className="landing-triangle" sx={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          width: 0,
          height: 0,
          borderLeft: { xs: '60vw solid transparent', md: '27vw solid transparent' },
          borderRight: { xs: '60vw solid transparent', md: '27vw solid transparent' },
          borderTop: { xs: '25vh solid #ffffff25', md: '23vh solid #ffffff25' },
          opacity: 0.8, 
          transform: 'translateX(-50%) rotate(180deg)',
          zIndex: 1
        }}>
          <Button
            variant="contained"
            color="primary"
            className="landing-button"
            onClick={handleClick}
            sx={{
              padding: '10px 30px',
              position: 'absolute',
              bottom: { xs: '75px', md: '55px' },
              left: '50%',
              transform: 'translateX(-50%) rotate(180deg)',
              whiteSpace: 'nowrap',  // Prevents text from wrapping
            }}
          >
            Find Your Properties
          </Button>

        </Box>
      </Box>
    </Box>
  );
};

export default Landing;
