// AboutUsTeam.tsx
import React from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton, Grid, Container, Box, useTheme } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { person } from '../../assets';

interface Agent {
  name: string;
  propertyType: 'Seller' | 'Buyer';
  imageUrl: string;
  facebookUrl?: string;
  linkedinUrl?: string;
  instagramUrl?: string;
}

const agents: Agent[] = [
  {
    name: 'John Doe',
    propertyType: 'Seller',
    imageUrl: 'https://via.placeholder.com/300x400', // Replace with your image URL
    facebookUrl: 'https://facebook.com/johndoe',
    linkedinUrl: 'https://linkedin.com/in/johndoe',
    instagramUrl: 'https://instagram.com/johndoe',
  },
  {
    name: 'Jane Smith',
    propertyType: 'Buyer',
    imageUrl: 'https://via.placeholder.com/300x400', // Replace with your image URL
    facebookUrl: 'https://facebook.com/janesmith',
    linkedinUrl: 'https://linkedin.com/in/janesmith',
    instagramUrl: 'https://instagram.com/janesmith',
  },
  {
    name: 'Alice Johnson',
    propertyType: 'Seller',
    imageUrl: 'https://via.placeholder.com/300x400', // Replace with your image URL
    facebookUrl: 'https://facebook.com/alicejohnson',
    linkedinUrl: 'https://linkedin.com/in/alicejohnson',
    instagramUrl: 'https://instagram.com/alicejohnson',
  },
];

const AboutUsTeam: React.FC = () => {

  const theme =- useTheme();
  return (
    <Box sx={{  backgroundColor: "transparent" }}>
    <Container sx={{px :{ xs : 2 , sm : 4 , md : 6 , lg : 8 } , py : 4 ,}}>
      <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
      Our Team
      </Typography>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: '700', mb : '24px' }}>
      Meet the Experts Behind Realty Yards
      </Typography>
      {/* description text  */}
      <Typography variant="body2" sx={{ textAlign: 'justify' , mb : 4 }}>
      Our team of professionals is dedicated to delivering excellence. With a deep understanding of the Hyderabad real estate market, we guide our clients through every step of the process, ensuring they receive the best service possible.
      </Typography>
      <Grid container spacing={4}>
        {agents.map((agent, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <CardMedia
                component="img"
                sx={{ height: '65%', objectFit: 'cover' }}
                image={person}
                alt={agent.name}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" sx={{ mb: 1 , textAlign: 'center' , fontWeight: 'bold' }}>
                  {agent.name}
                </Typography>
                <Typography variant="body1" color="primary" sx={{ mb: 2 , textAlign: 'center' , fontWeight: '450' }}>
                  {agent.propertyType}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 , textAlign: 'center' , flexWrap : 'wrap' , justifyContent : 'center'}}>
                  {agent.facebookUrl && (
                    <IconButton
                      href={agent.facebookUrl}
                      target="_blank"
                      aria-label="Facebook"
                      sx={{ color: '#3b5998' }}
                    >
                      <FacebookIcon />
                    </IconButton>
                  )}
                  {agent.linkedinUrl && (
                    <IconButton
                      href={agent.linkedinUrl}
                      target="_blank"
                      aria-label="LinkedIn"
                      sx={{ color: '#0077b5' }}
                    >
                      <LinkedInIcon />
                    </IconButton>
                  )}
                  {agent.instagramUrl && (
                    <IconButton
                      href={agent.instagramUrl}
                      target="_blank"
                      aria-label="Instagram"
                      sx={{ color: '#e1306c' }}
                    >
                      <InstagramIcon />
                    </IconButton>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
    </Box>
  );
};

export default AboutUsTeam;
