import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  IconButton,
  Box,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomInput from '../Inputs/CustomInput';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PDF } from '../../assets'; 

interface ModalFormProps {
  open: boolean;
  handleClose: () => void;
  projectId: string;
}

const ModalForm: React.FC<ModalFormProps> = ({ open, handleClose, projectId }) => {
  const theme = useTheme();

  const ProjectNames = [
    'Giridhari Prospera',
    'Visions Arsha Apartments',
    'Moon Glade Apartments',
    'Acasa Apartments',
    'Lacasa Villa',
    'Nivasa Apartments',
    'Skyven Apartments',
  ];

  // Map of projectId to PDF file names
  const pdfMap: { [key: string]: string } = {
    '1': PDF.giridharipdf, // Assuming you have imported this from your assets
    '2': PDF.visionspdf,
    '3': PDF.MGpdf,
    '4': PDF.acasapdf,
    '6': PDF.nivasapdf,
  };

  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    mobile: '',
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);

  // Validate form whenever formValues change
  useEffect(() => {
    const isValid =
      formValues.name.trim() !== '' &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email) &&
      /^[0-9]{10}$/.test(formValues.mobile);
    setIsFormValid(isValid);
  }, [formValues]);

  // Handle input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  

  // Handle form submission
  const handleSubmit = async () => {
    // downloadPDF(projectId);
    // handleClose();
    // resetForm();
    // toast.success('Brochure request sent successfully!');

    if (!isFormValid) {
      toast.error('Please fill out the form correctly.');
      return;
    }

    setLoading(true); // Start loading

    try {
      // Send form data to the mailer API
      const response = await axios.post('https://api.realtyyards.com/emailform', {
        name: formValues.name,
        email: formValues.email,
        mobileNumber: formValues.mobile,
      });

      if (response.status === 201) {
        toast.success('Brochure request sent successfully!');
        downloadPDF(projectId);
        handleClose();
        resetForm();
      } else {
        toast.error('Failed to send the brochure request.');
      }
    } catch (err) {
      toast.error('An error occurred while sending the request.');
    } finally {
      setLoading(false);
    }
  };

  const downloadPDF = (id: string) => {
    const pdfUrl = pdfMap[parseInt(id) - 1]; 
  
    console.log(id, pdfUrl,"pdfurl");
  
    if (!pdfUrl) {
      toast.error('No PDF URL found for the selected project. Cannot download brochure.');
      return;
    }
  
    const projectIndex = parseInt(id) - 1; 
    
    if (projectIndex < 0 || projectIndex >= ProjectNames.length) {
      toast.error('No project found for the selected ID. Cannot download brochure.');
      return;
    }
  
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `${ProjectNames[projectIndex]}.pdf`; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Reset form values
  const resetForm = () => {
    setFormValues({
      name: '',
      email: '',
      mobile: '',
    });
    setIsFormValid(false);
  };

  // Handle dialog close and reset form
  const handleDialogClose = () => {
    resetForm();
    handleClose();
  };

  return (
    <>
      <ToastContainer />
      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor: 'rgba(255, 255, 255, 0.9)', // Adjusted for better visibility
            width: '100%',
            boxShadow: 'none',
            color: '#000', // Changed to black for better readability
            position: 'relative',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            right: '20px',
            top: '10px',
          }}
        >
          <IconButton edge="end" color="inherit" onClick={handleDialogClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogTitle
          sx={{
            padding: theme.spacing(2),
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Fill in Your Details
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            padding: theme.spacing(2),
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomInput
                name="name"
                placeholder="Your Name"
                value={formValues.name}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                name="email"
                type="email"
                placeholder="Your Email"
                value={formValues.email}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                name="mobile"
                type="tel"
                placeholder="Your Mobile Number"
                value={formValues.mobile}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            padding: theme.spacing(1),
          }}
        >
          <Button
            onClick={handleDialogClose}
            variant="outlined"
            color="secondary"
            sx={{
              marginRight: 'auto',
              marginLeft: 2,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={!isFormValid || loading} // Disable if form is invalid or loading
            sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}
          >
            {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Submit & Download'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalForm;
